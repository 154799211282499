header.head{
  width: 100%;
  //background: rgba(255, 255, 255, 0.97);
  background: rgba(255, 255, 255, 0);
  //min-height: 88px;
  display: flex;
  align-items: center;
  transition: min-height .2s linear 0s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 158px;
  z-index: 9;
}
body.home-page-body header.head{

}
body.home-page-body header.head.fixed, header.head.fixed{
  background: rgba(255, 255, 255, 0.97);
  z-index: 8;
  min-height: 88px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}
.head-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo{
  font-size: 28px;
  font-weight: bold;
  &__link{
    max-width: 184px;
    display: block;
    img{
      width: 100%;
      height: auto;
    }
  }
}

.burg-line{
  height: 2px;
  width: 28px;
  background-color: #000000;
  transition: background-color .2s linear 0s;
}
.btn--burger:hover .burg-line{
  background-color: #888888;
}

