.sidebar-bg{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 9;
}

.side-wrapper{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.side-nav{
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -100%;
  left: auto;
  width: 100%;
  height: 100%;
  max-width: 720px;
  background: #FFFFFF;
  box-shadow: 0px 2px 64px rgba(0, 0, 0, 0.173241);
  padding: 30px 30px 0 82px;
  transition: right .3s linear 0s, top .3s linear 0s;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  &__row{
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    &--close{
      justify-content: flex-end;
      margin-bottom: 25px;
    }
    &--lang{
      margin-bottom: 0;
    }
  }
  &__group .side-nav__row:last-child{
    margin-bottom: 0;
  }
  &__group{
    margin: 0;
    padding-bottom: 0;
    min-height: 640px;

  }
  &__group-2{
    display: flex;
    border-top: 1px solid #d1d1d1;
    padding: 35px 0;
    justify-content: flex-start;
    align-items: baseline;
  }
  &__cap{
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    width: 100%;
    max-width: 180px;
    margin-right: 15px;
    user-select: none;
    transition: color .2s linear 0s;
    &--link{
      width: auto;
      max-width: none;
      line-height: 24px;
      margin: 0 42px 0 0;
    }
    &:hover{
      color: #0166FF;
    }
  }
  &__links{
    &--lang{
      display: flex;
      align-items: center;
      flex-direction: row;

      .lang-divider{
        line-height: 24px;
        margin: 0 5px;
        font-size: 14px;
      }
    }
  }
  &__link{
    font-size: 14px;
    display: block;
    color: #6F6F6F;
    line-height: 24px;
    position: relative;
    transition: color .2s linear 0s;
    &:hover{
      color: #0166FF;
    }
  }

  .lang-link{
    position: relative;
    display: inline-block;
    line-height: 24px;
    &--selected{
      color: #000000;
      &:after{
        bottom: 0;
        background-color: #0166FF;
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        display: block;
        left: 0;
      }
    }
  }
}



.side-nav.nav-toggled{
  right: 0;

}

