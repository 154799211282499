*{
  box-sizing: border-box;
}
body{
  background: #F2F2F2;
  position: relative;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}
a, a:hover, a:link, a:visited, a:focus{
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
input{
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

button{
  border: none;
  outline: none;
  box-shadow: none;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  text-align: center;
}

p{
  margin: 0;
}

img{
  display: block;
  width: 100%;
  height: auto;
}

ul,li{
  padding: 0;
  margin: 0;
  list-style-type: none;
}


body.doc-body.side-toggled{
  overflow: hidden;
}

//--------------------------Containers-----------------------------------

.content-wrapper{
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.content-holder, .head-nav, .foot-nav, .foot-form-block{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.s-to-right{
  display: flex;
  justify-content: flex-end;
}
.content-holder{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &--info{
    max-width: 810px;
  }
}


//------------------------------Headers, paragraphs------------------------------
.section-header{
  max-width: 1140px;
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  color: #0166FF;
  text-align: left;
  &--centred{
    text-align: center;
    line-height: 55px;
  }
}
.page-header{
  font-weight: bold;
  font-size: 72px;
  line-height: 54px;
  text-align: left;
  color: #0166FF;
}
.service-page-body .section-header{
    font-size: 64px;
}


.sup-header{
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: rgba(1, 102, 255, 0.49664);
}
//-----------------------------Decoration------------------------------------------

.fc-blue{
  color: #0166FF;
}
.fw-bold{
  font-weight: bold;
}
.underlined{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    display: block;
    bottom: 0;
    left: 0;
    background-color: #0166ff00;
    transition: background-color .2s ease 0s;
  }
}

input.error{
  box-shadow: 0 0 0 1px #ff0000;
  color: #ff0000;
  &::-webkit-input-placeholder { color: #ff0000; }
  &::-moz-placeholder          { color: #ff0000; }
  &:-moz-placeholder           { color: #ff0000; }
  &:-ms-input-placeholder      { color: #ff0000; }
}

//-------------------------------Buttons, Links--------------------------------
.btn{
  cursor: pointer;
  display: flex;
  &__line{
    height: 2px;
    width: 28px;
    background-color: #000000;
    transition: background-color .2s linear 0s;
  }
  &--burger{
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    padding: 6px 0;
  }
  &--close{
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    position: relative;
    .btn__line{
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 14px);
      transform: rotate(45deg);
    }
    .btn__line:last-child{
      transform: rotate(-45deg);
    }
  }
  &--link{
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-transform: uppercase;
    color: #0166FF;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    border: 1px solid #0166FF;
    transition: color .2s linear 0s,
                background-color .2s linear 0s,
                transform .2s cubic-bezier(.27,.13,.42,1.36) 0s;
    &:active, &:focus, &:hover{
      color: #fff;
      background: #0166FF;
      transform: translateY(-5px);

      &::before {
        opacity: 1;
        transform: translateY(5px);
      }
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;

      top: 90%;
      left: 5%;
      height: 30px;
      width: 100%;
      opacity: 0;
      background: radial-gradient(ellipse at top, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 80%);
      transition-duration: 0.3s;
      transition-property: transform, opacity;
    }
  }
  &--submit{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .2s linear 0s, color .2s linear 0s;

    background-color: #0166FF;
    color: #FFFFFF;
    &:hover{
      background-color: #042999
    }

  }
  &--vacancy{
    width: 100%;
    max-width: 515px;
    height: 72px;
    background-color: #FFFFFF;
    text-transform: none;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-top: 50px;
    &[disabled]{
      border: #F5F5F7;
      background: #F5F5F7;
      color: #888888;
      cursor: initial;
      &:hover{
        transform: none;
        &:before{
          display: none;
        }
      }
    }
  }
  &--to-top{
    display: block;
    position: fixed;
    border-radius: 40px;
    overflow: hidden;
    bottom: 40px;
    right: 40px;
    img{
      width: 52px;
    }
  }
  &--share{
    width: 44px;
    height: 44px;
    background: #F5F5F7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0166FF;
    margin: 0 30px 0 0;
    font-size: 24px;
    transition: background-color .3s ease 0s, color .3s ease 0s;
    &:last-child{
      margin: 0;
    }

    &:hover{
      background-color: #0166FF;
      color: #FFFFFF;
    }
  }
  &--connect{
    background: #F5F5F7;
    border-radius: 4px;
    width: 100%;
    max-width: 360px;
    height: 64px;
    font-size: 18px;
    line-height: 22px;
    color: #1D1D1F;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    padding-left: 16px;
    transition: background-color .3s ease 0s, color .3s ease 0s;
    margin-bottom: 30px;
    i.fab{
      font-size: 36px;
      color: #0166FF;
      line-height: 32px;
      margin-right: 16px;
      transition: color .3s ease 0s;
    }
    &:hover{
      background-color: #ECECED;
    }
  }
}
.btn:hover .btn__line{
  background-color: #888888;
}

.text-link{
  transition: color .2s ease 0s;
  &--grey:hover{
    color: #333333;
  }
  &--blue:hover{
    color: #042999;
  }
}

//------------------------------Common blocks---------------------------------

.text-block{
  max-width: 50%;
  width: 100%;
  &__list{
    margin: 53px 0 0 0;
    max-width: 540px;
  }
  &__list-cap{
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1F;
  }
  &__list-item{
    padding-left: 31px;
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1F;
    font-weight: bold;
    position: relative;
    margin: 22px 0;
    &:before{
      content: url("../img/check-mark.svg");
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      top: 1px;
      left: 0;
    }
    &--m-dash{
      &:before{
        content: '\2014';
        display: block;
        position: absolute;
        width: auto;
        height: 28px;
        top: 0;
        left: 0;
      }
    }
  }
}

.c-grid{
  width: 100%;
  &__item-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }

  &__item{
    display: flex;
    flex-direction: column;
    width: 521px;
  }
}

.grid{
  display: flex;
  width: 100%;
  &__cont{
    width: 100%;
  }
}

.block-header{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0;
  .sup-header{
    text-align: left;
    margin: 0 0 0 3px;
  }
}

.s-entrance, .s-starting{
  padding-top: 158px;
}

.s-starting{
  display: flex;
  position: relative;
  padding-bottom: 20px;
  min-height: 768px;
  .text-block{
    position: relative;
    z-index: 2;
    &__text{
      font-size: 24px;
      line-height: 32px;
      color: #1D1D1F;
    }
  }
  .illustration{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &--puzzle{
      width: 304px;
      height: 302px;
      position: relative;
    }
    &__fragment{
      position: absolute;
      width: auto;
      height: auto;
      &--inno1{
        width: 238px;
        top: 0;
        left: 0;
      }
      &--inno3{
        width: 31px;
        left: 14px;
        top: 118px;
      }
      &--inno4{
        width: 85px;
        left: 62px;
        bottom: 0;
      }
      &--inno2{
        width: 115px;
        bottom: 24px;
        left: 0;
      }
      &--inno5{
        width: 162px;
        bottom: 0;
        right: 10px;
      }

      &--inter1{
        width: 112px;
        right: 191px;
        bottom: -10px;
      }
      &--inter4{
        width: 79px;
        right: 139px;
        bottom: -9px;
      }
      &--inter2{
        width: 138px;
        right: 58px;
        bottom: 145px;
        z-index: 2;
      }
      &--inter3{
        width: 62px;
        right: 0;
        bottom: 207px;
      }
      &--inter5{
        width: 143px;
        right: 3px;
        bottom: 50px;
      }
      &--inter6{
        width: 150px;
        right: 0;
        bottom: -6px;
      }

      &--comm1{
        width: 101px;
        right: 203px;
        bottom: 175px;
      }
      &--comm2{
        width: 126px;
        right: 65px;
        bottom: 131px;
      }
      &--comm3{
        width: 105px;
        right: 39px;
        bottom: 196px;
      }
      &--comm4{
        width: 156px;
        right: 148px;
        bottom: -2px;
      }
      &--comm5{
        width: 160px;
        right: 3px;
        bottom: 29px;
      }
      &--comm6{
        width: 66px;
        right: 4px;
        bottom: -5px;
      }

      &--intern1{
        width: 115px;
        right: 3px;
        bottom: 178px;
      }
      &--intern2{
        width: 236px;
        right: 66px;
        bottom: 54px;
      }
      &--intern3{
        width: 117px;
        right: 84px;
        bottom: -10px;
      }
      &--intern4{
        width: 106px;
        right: 0px;
        bottom: 71px;
      }
      &--intern5{
        width: 116px;
        right: 32px;
        bottom: 21px;
      }
    }
  }

  &--service{
    min-height: auto;
    padding-bottom: 80px;
    .content-holder{
      align-items: flex-end;
      margin-top: 125px;
    }
    .text-block{
      max-width: none;
      &__text{
        margin-top: 63px;
        max-width: 50%;
      }
    }
  }
  &--post{
    min-height: auto;
    padding-bottom: 25px;
    .content-holder{
      margin-top: 75px;
    }
    .page-header{
      line-height: 80px;
    }
    .text-block{
      width: auto;
      max-width: 945px;
      &__text{
        margin-top: 25px;
      }
    }
  }
}

.s-diagonal{
  display: flex;
  &:active, &:focus{
    outline: none;
  }
  .content-holder{
    flex-direction: column;
  }
  overflow: hidden;
}

.diagonal-block{
  position: relative;
  padding-top: 186px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  .illustration{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 750px;
    overflow: hidden;
    background: #f1f1f1;
  }
  .text-block{
    max-width: 570px;
    position: relative;
    background: #ffffff;
    padding: 52px 0 52px 52px;
    border-radius: 12px 0 0 0;
    min-height: 389px;
    box-shadow: -60px -50px 70px -80px rgba(127, 127, 127, 0.532);
    &__cap{
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      color: #1D1D1F;
      margin-bottom: 30px;
      &--m-t{
        margin: 30px 0;
      }
    }
    &__text{
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F;
      margin: 0 0 15px 0;
    }
    &__list-cap{
      font-size: 18px;
    }
    &__list-item{
      font-size: 18px;
    }
  }
  &--to-right{
    justify-content: flex-start;
    .illustration{
      left: auto;
      right: 0;
    }
    .text-block{
      box-shadow: 60px -50px 70px -80px rgba(127, 127, 127, 0.532);
      padding: 52px 45px 52px 0;
      border-radius: 0 12px 0 0;
    }
  }
}

.s-info{
  padding: 120px 0 33px 0;
  .content-holder{
    flex-direction: column;
  }
  .section-header{
    font-size: 64px;
    margin-bottom: 50px;
  }
  .text-block{
    max-width: none;
    &__text{
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F;
    }
    &__list{
      max-width: 596px;
      margin: 50px 0 25px 0;
    }
    &__list-item{
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F;
    }
  }
}

.multi-wrapper{
  padding-bottom: 68px;
}


div.styles{
  display: flex;
  padding: 180px 0;
  .example{
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    width: 1000px;
    margin: auto;
  }
  .content-holder{
    max-width: 750px;
    flex-direction: column;
  }
  .fc-blue{
    width: 200px;
  }
}

//--------------------------------------Fonts----------------------------------
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "fa";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
//------------------------------------Animations----------------------------
.zoomOut{
  transform: scale3d(1.3, 1.3, 1.3);
  visibility: visible!important;
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

//--------------------------------------Over-------------------------------------

//Youtube
