.s-entrance{
  flex-direction: column;
  .content-holder{
    display: flex;
  }

  .text-block{
    margin-top: 130px;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .section-header{
      max-width: 944px;
      text-align: center;
    }
    &__text{
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: #888888;
      max-width: 500px;
      text-align: center;
      margin-top: 34px;
    }
  }

  .thumbs{
    margin: 108px auto -60px auto;
    max-width: 1440px;

    &__row{
      display: flex;
      &--top{
        align-items: flex-end;
        margin-bottom: 32px;
        .thumbs__thumb--mid{
          width: 726px;
        }
        .thumbs__thumb--side{
          width: 325px;
        }
      }
      &--bot{
        align-items: flex-start;
        .thumbs__thumb--mid{
          width: 476px;
        }
        .thumbs__thumb--side{
          width: 450px;
        }
      }
    }

    &__thumb{
      overflow: hidden;
      position: relative;
      transition: box-shadow .4s ease 0s;
      &--mid{
        margin: 0 32px;
      }
    }

    &__overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000000;
      opacity: 0;
      transition: opacity .4s ease .1s;
      &:hover {
        opacity: 0.15;
      }
    }

  }

}

.s-overview{
  background: linear-gradient(180deg, #F5F5F7 2.6%, rgba(245, 245, 247, 0.0001) 100%);
  padding-top: 60px;
  .content-holder{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px;
  }

  .text-block{
    margin-top: 138px;

  }

  .illustration{
    width: 458px;
    margin-top: 140px;

  }
}

.s-service{
  .content-holder{
    &--1{
      flex-direction: column;
      padding-bottom: 40px;
    }
    &--2{
      padding-bottom: 112px;
    }
  }

  .text-block{
    max-width: 750px;
    margin: 140px auto 0 auto;
    &__text{
      margin: 55px 0 0 0;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      color: #1D1D1F;
    }
  }
  .illustration{
    margin: 95px auto 0 auto;
    width: 100%;
    max-width: 860px;
  }

  .c-grid{
    margin: 100px 0 0 0;
    &__item{
      padding: 32px;
      margin-bottom: 28px;
      min-height: 442px;
      justify-content: space-between;
    }
    &__img{
      width: 110px;
      margin: 0 auto;
    }
    &__cap{
      margin: 30px 0;
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      color: #1D1D1F;
      text-align: center;
    }
    &__text{
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #1D1D1F;
    }

    &__item .btn--link{
      margin: 10px auto;
      width: 224px;
      height: 56px;
    }
  }

}

.service-tape{
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0 auto;
  &__item{
    width: 100%;
    max-width: 33%;
    flex-shrink: 0;
    overflow: hidden;
    &:last-child{
      margin: 0 0 0 0;
    }
  }
}

.s-stories{
  background: linear-gradient(180deg, #F5F5F7 2.6%, rgba(245, 245, 247, 0.0001) 100%);
  &--page{
    background: #FFFFFF;
    padding-top: 60px;
  }
  .content-holder{
    flex-direction: column;
    padding-bottom: 112px;
  }
  .section-header{
    margin: 102px auto;
  }

  .c-grid{
    &__img{
      transition: transform .2s linear 0s;
    }
    &__item-wrapper{
      transition: height .3s linear 0s;
    }
    &__item{
      border-radius: 7px;
      background-color: #ffffff;
      margin-bottom: 88px;
      padding: 0 0 30px 0;
      box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.131693);
      &:hover{
        .c-grid__img{
          transform: scale(1.2);
        }
      }
    }

    &__img-container{
      width: 100%;
      height: 255px;
      overflow: hidden;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }

    &__cap, &__text{
      padding: 0 50px 0 30px;
    }

    &__cap{
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #1D1D1F;
      margin: 50px 0 0 0;
    }
    &__text{
      font-size: 16px;
      line-height: 24px;
      color: #888888;
      margin: 15px 0 0 0;
      min-height: 82px;
    }
  }

  .btn--link{
    width: 360px;
    height: 72px;
    margin: 17px auto 0 auto;
  }
}