.sub-nav-wrapper{
  box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.sub-nav{
  position: relative;
  &__arrow{
    position: absolute;
  }
  &__group{
    min-height: 40px;
    position: relative;
    display: flex;
  }
  &__link{
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #888888;
    padding: 0 28px;
    transition: color .2s linear 0s;
    &:hover{
      color: #1D1D1F;
    }
  }
  &__item--current .sub-nav__link{
    color: #1D1D1F;
    &:hover{
      color: #1D1D1F;
    }
  }

  .magic-line{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background: #1D1D1F;
  }
}
