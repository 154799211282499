@media screen and (max-width: 1439px) {

  .s-about {
    .content-holder{
      align-items: center;
    }
    .text-block{
      margin: 0;
    }
    .illustration{
      margin: 0;
      padding-left: 20px;
    }
  }


}

@media screen and (max-width: 1199px) {
  .content-holder, .head-nav, .foot-nav, .foot-form-block{
    padding: 0 26px;
    max-width: 940px;
  }

  .content-holder{
    &--info{
      max-width: 750px;
    }
  }

  .page-header, {
    font-size: 64px;
  }
  .service-page-body .section-header{
    font-size: 48px;
  }

  .s-entrance{
    .text-block{
      margin-top: 50px;
      .section-header{
        font-size: 42px;
        line-height: 117%;
      }
      &__text{
        margin-top: 45px;
        font-size: 22px;
      }
    }
    .thumbs{
      margin-top: 80px;
    }
  }

  .s-starting{
    .text-block{
      &__text{
        font-size: 20px;
        line-height: 28px;
      }
      padding: 0 0 50px 0;
    }
    .illustration{
      width: 50%;
      &__img{
        margin: 0;
      }
      &--puzzle{
        width: 304px;
        height: 302px;
      }
    }

    &--service{
      .text-block{
        padding: 0;
      }
    }
  }

  .s-info .section-header, .s-engagement .section-header, .s-jobs .section-header{
      font-size: 48px;
  }


  .diagonal-block{
    .text-block{
      min-height: 314px;
      &__cap{
        font-size: 18px;
        margin: 0 0 20px 0;
        &--m-t{
          margin: 20px 0;
        }
      }
      &__text{
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .hmi-list{
    &__item{
      align-items: center;
    }
    .text-block{
      margin: 0;
      &__text{
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .s-achieves {
    padding-bottom: 30px;
  }
  .label{
    &--tape{
      max-width: 210px;
      height: 155px;
      padding: 10px;
    }
    &__cap{
      font-size: 46px;
    }
    &__text{
      font-size: 16px;
      line-height: 20px;
    }
  }

  .s-team{
    padding-bottom: 0;
  }
  .teammate {
    width: 210px;
    height: 140px;
    margin: 0 16px 16px 0;
    &__text{
      padding: 0 0 5px 8px;
    }
    &__name {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
    }
    &__pos{
      line-height: 18px;
      font-size: 13px;
    }
  }

  .s-customers{
    .grid__cont {
      max-width: 940px;
    }
  }

  .s-jobs{
    .text-block__list{
      margin: 20px 0 0 0;
    }
  }

  .c-grid{
    &__item{
      width: 425px;
    }
  }
  .s-stories{
    .c-grid__item{
      margin-bottom: 40px;
    }
  }


  .foot-nav__links-col{
    max-width: 190px;
  }
  .foot-nav__contacts{
    width: 25%;
  }
  .foot-nav__copyright{
    width: auto;
    margin: 54px auto 0 auto;
  }
}

@media screen and (max-width: 991px) {
  .head-nav{
    padding: 0 24px;
  }
  .content-holder, .foot-nav, .foot-form-block,
  .s-post .content-holder, .s-share .content-holder{
    padding: 0 57px;
    max-width: 768px;
  }

  .btn--to-top{
    right: 30px;
    bottom: 30px;
  }
  .btn--connect{
    max-width: 310px;
  }

  .s-entrance, .s-starting {
    padding-top: 88px;
  }

  header.head{
    min-height: 88px;
  }
  .logo{
    font-size: 24px;
  }
  .side-nav{
    max-width: none;
    padding: 27px 27px 0 27px;
    &__group, &__group-2, &__row{
      width: 100%;
      max-width: 468px;
    }
    &__row{
      margin: 0 auto 30px auto;
      &--close{
        max-width: none;
        margin-bottom: 25px;
      }
      &--lang{
        margin-bottom: 0;
      }
    }
    &__group, &__group-2{
      margin: 0 auto;
    }
    &__group-2{
      justify-content: space-between;
    }
    &__cap{
      max-width: 205px;
      &--link{
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .s-entrance{
    .content-holder{
      flex-direction: column;
      justify-content: flex-start;
      min-height: auto;
    }

    .text-block{
      order: 2;
      max-width: none;
      margin-top: 70px;
      .section-header{
        br{display: none}
        width: 100%;
        max-width: none;
        order: 1;
      }
      &__text{
        width: 100%;
        max-width: none;
        order: 3;
        margin-top: 70px;
      }
    }
  }

  .s-overview .text-block, .s-service .text-block, .s-service .c-grid{
    margin-top: 70px;
  }

  .s-overview{
    .content-holder{
      flex-direction: column;
      align-items: center;
    }
    .illustration{
      margin-top: 70px;
      padding-right: 40px;
    }
    .text-block{
      max-width: none;
    }
  }

  .s-service{
    .content-holder--2{
      padding-bottom: 70px;
    }
  }

  .s-starting{
    align-items: flex-start;
    min-height: 614px;
    padding-bottom: 40px;
    .content-holder{
      flex-direction: column;
    }
    .text-block{
      order: 2;
      width: 100%;
      max-width: none;
      margin: 0 0 0 0;
      padding: 0;
      &__text{
        width: 100%;
        margin-top: 38px;
      }
    }
    .illustration{
      order: 1;
      width: 100%;
      &__img{
        margin: 10px 0 0 0;
        max-width: 158px;
      }
      &--puzzle{
        width: 254px;
        height: 242px;
        align-self: flex-end;
        margin: 6px 0 -52px 0;
      }
      &__fragment{
        &--inno1{
          width: 160px;
          top: auto;
          left: auto;
          right: 35px;
          bottom: 112px;
        }
        &--inno2{
          width: 78px;
          bottom: 16px;
          left: auto;
          right: 119px;
        }
        &--inno3{
          width: 22px;
          left: auto;
          right: 165px;
          top: auto;
          bottom: 36px;
        }
        &--inno4{
          width: 58px;
          right: 98px;
          left: auto;
        }
        &--inno5{
          width: 108px;
          right: 0;
        }

        &--inter1{
          width: 75px;
          bottom: -4px;
          right: 128px;
        }
        &--inter2{
          width: 92px;
          bottom: 100px;
          right: 39px;
        }
        &--inter3{
          width: 42px;
          bottom: 141px;
          right: 0;
        }
        &--inter4{
          bottom: 0;
          right: 93px;
          width: 52px;
        }
        &--inter5{
          width: 95px;
          bottom: 38px;
          right: 2px;
        }
        &--inter6{
          width: 100px;
          bottom: 0;
          right: 0;
        }

        &--comm1{
          width: 67px;
          right: -3px;
          bottom: 78px;
        }
        &--comm2{
          width: 84px;
          right: 91px;
          bottom: 138px;
        }
        &--comm3{
          width: 70px;
          right: 74px;
          bottom: 179px;
        }
        &--comm4{
          width: 104px;
          right: 139px;
          bottom: 52px;
        }
        &--comm5{
          width: 106px;
          right: -4px;
          bottom: 5px;
        }
        &--comm6{
          width: 44px;
          right: 80px;
          bottom: 80px;
        }

        &--intern1{
          width: 80px;
          right: 48px;
          bottom: 161px;
        }
        &--intern2{
          width: 155px;
          right: 100px;
          bottom: 83px;
        }
        &--intern3{
          width: 78px;
          right: 60px;
          bottom: 80px;
        }
        &--intern4{
          width: 72px;
          right: 2px;
          bottom: 25px;
        }
        &--intern5{
          width: 79px;
          right: 26px;
          bottom: 50px;
          transform: scale(1, -1);
        }
      }
    }

    &--service{
      min-height: auto;
      .content-holder{
        margin-top: 70px;
      }
      .text-block__text{
        max-width: none;
      }
    }
    &--post{
      .page-header{
        line-height: 111%;
      }
    }
  }

  .block-header{
    padding: 52px 0 34px 0;
  }
  .s-info{
    .text-block__list{
      max-width: none;
    }
  }
  .diagonal-block{
    .illustration {
      left: -57px;
      max-width: 620px;
    }
    .text-block{
      max-width: 500px;
      padding: 45px 0 45px 48px;
      min-height: 300px;
    }
    &--to-right{
      margin-top: 35px;
      .illustration{
        left: auto;
        right: -57px;
      }
      .text-block{
        padding: 45px 48px 45px 0;
      }
    }
  }
  .text-block__list{
    width: 100%;
    max-width: none;
  }

  .s-hmi{
    .content-holder{
      margin-top: 0;
    }
  }
  .hmi-list{
    margin: 37px 0 0 0;
    &__item{
      flex-direction: column;
      padding: 0;
      margin: 108px 0 0 0;
      &:first-child{
        margin: 0;
      }
      &:last-child{
        padding-bottom: 80px;
      }
    }
    &__item-num {
      top: -2px;
      font-size: 24px;
    }
    .text-block{
      max-width: none;
      padding: 0;
      &__cap{
        font-size: 24px;
      }
      &__text{
        margin: 25px 0 0 0;
      }
    }
    .illustration{
      max-width: none;
      margin: 35px 0 0 0;

      &__img{
        width: 100%;
        max-height: 304px;
      }
    }
  }

  .s-testing{
    padding-bottom: 70px;
  }

  .s-about{
    align-items: flex-end;
    .about-top-pic{
      display: none;
    }
    .illustration__img{
      max-width: 320px;
    }
    &--contacts{
      .illustration{
        padding: 0;
        width: 100%;
        max-width: none;
        &__img{
          max-width: none;
        }
      }
    }
  }

  .s-achieves{
    .illustration{
      max-width: none;
    }
    .labels{
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .label{
      max-width: calc(50% - 8px);
      margin-bottom: 16px;
      height: 200px;
      &:last-child{
        margin: 0 0 16px 0;
      }
      &__cap{
        font-size: 64px;
        margin-bottom: 15px;
      }
      &__text {
        font-size: 20px;
        line-height: 20px;
        max-width: 190px;
      }
    }
  }

  .s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    max-width: 520px;
    left: calc(50% - 260px);
  }

  .teammate {
    width: 279px;
    height: 186px;
    margin: 0 16px 16px 0;
  }

  .s-customers{
    .grid{
      justify-content: center;
      margin: 0;
      width: 100%;
      &__cont{
        max-width: 654px;
        padding: 20px 0;
      }
    }
    .customer{
      margin: 0 0 40px 0;
      width: 33%;
    }
  }

  .s-partners{
    .partners-grid{
      margin-bottom: 30px;
    }
    .grid{
      width: 100%;
      margin: 0;
    }
    .partner{
      margin: 20px 0;
    }
  }

  .label--social {
    height: 120px;
    max-width: 210px;
    .label__img {
      max-height: 57px;
      max-width: 170px;
      mix-blend-mode: multiply;
    }
  }


  .c-grid__item{
    width: calc(50% - 20px);
  }

  .s-service {
    .c-grid__img {
      width: 90px;
    }
    .c-grid__item{
      padding: 20px 10px;
      min-height: 390px;
    }
    .c-grid__cap{
      font-size: 26px;
      margin: 15px 0;
    }
    .c-grid__text {
      font-size: 14px;
      line-height: 22px;
    }
    .c-grid__item .btn--link {
      margin: 10px auto;
      width: 180px;
      height: 46px;
      font-size: 14px;
    }
  }

  .s-stories {
    .c-grid__item{
      margin-bottom: 40px;
    }
    .c-grid__img-container{
      height: 175px;
    }
    .c-grid__cap{
      margin: 20px 0 0 0;
      padding: 0 15px 0 15px;
      font-size: 18px;
    }
    .c-grid__text{
      padding: 0 20px 0 20px;
      font-size: 14px;
    }
  }

  .f-contacts{
    &__input{
      max-width: 310px;
    }
    &__ta{
      height: 240px;
    }
  }

  .s-connect{
    padding-bottom: 140px;
  }

  .footer-form__input {
    width: calc(100% - 125px);
  }
  .footer-form__submit{
    width: 125px;
  }
  .foot-nav{
    &__links{
      max-width: 450px;
    }
    &__links-row{
      justify-content: space-between;
    }
    &__link{
      max-width: 143px;
      &:last-child{
        margin: 0;
      }
    }
    &__contacts{
      width: auto;
      max-width: none;
      padding: 8px 4px;
    }
    &__copyright{
      margin: 83px 0 0 0;
    }
    &__logo{
      order: 4;
      margin: 40px 0 0 0;
    }
  }
}

@media screen and (max-width: 767px) {
  body.home-page-body header.head.fixed, header.head.fixed, header.headп{
    min-height: 56px;
  }
  .content-holder, .foot-form-block,
  .s-post .content-holder, .s-share .content-holder{
    padding: 0 30px;
    max-width: 544px;
  }



  .s-starting{
    .illustration--puzzle{
     margin: 0 0 0 0;
    }
  }
  .s-starting--post {
    min-height: auto;
    .content-holder{
      margin-top: 50px;
    }
    .text-block{
      margin-top: 0;
    }
    .page-header{
      font-size: 52px;
      line-height: 111%;
    }
  }

  .btn--vacancy {
    max-width: 290px;
    height: 60px;
    font-size: 16px;
    margin: 50px auto 0 auto;
  }
  .btn--share {
    width: 36px;
    height: 36px;
    margin: 0 20px 0 0;
    font-size: 18px;

  }
  .btn--connect{
    margin-bottom: 15px;
    max-width: 235px;
    height: 48px;
  }

  .s-entrance{
    .text-block, .text-block__text{
      margin-top: 50px
    }
  }

  .diagonal-block{
    .illustration{
      max-width: 460px;
    }
  }

  .s-info{
    padding: 84px 0 0 0;
  }

  .s-internationalization .page-header{
      font-size: 55px;
  }

  .s-research, .s-hmi, .s-prototyping, .s-ergonomics, .s-testing{
    padding-top: 30px;
  }

  .s-achieves .label {
    max-width: 234px;
    margin: 0 0 16px 0;
    height: 180px;
    &__cap{
      font-size: 46px;
    }
    &__text{
      font-size: 16px;
    }
    &:last-child{
      margin: 0 0 16px 0;
    }
  }

  .teammate {
    width: 210px;
    height: 140px;
  }

  .s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal{
    max-width: 400px;
    left: calc(50% - 200px);
  }

  .s-customers{
    .grid__cont{
      max-width: 484px;
    }
    .customer{
      padding: 0 20px;
      justify-content: center;
      &:first-child, &:nth-child(3n + 4){
        padding: 0 20px 0 0;
      }
      &:nth-child(3n), &:last-child{
        padding: 0 0 0 20px;
      }
    }
  }

  .s-partners{
    .partner{
      max-width: 33%;
      padding: 0 20px;
      &:first-child, &:nth-child(3n + 4){
        padding: 0 20px 0 0;
      }
      &:nth-child(3n){
        padding: 0 0 0 20px;
      }
    }
  }

  .c-grid{
    &__item{
      width: 100%;
      max-width: none;
    }
  }

  .s-service {
    .c-grid__item{
      padding: 32px;
      min-height: 442px;
    }
    .c-grid__img {
      width: 110px;
    }
    .c-grid__cap {
      margin: 30px 0;
      font-size: 36px;
      line-height: 48px;
    }
    .c-grid__text {
      font-size: 16px;
      line-height: 24px;
    }
    .c-grid__item .btn--link {
      width: 224px;
      height: 56px;
    }
  }

  .s-stories .c-grid__cap,
  .s-stories .c-grid__text {
    padding: 0 50px 0 30px;
  }
  .s-stories {
    .c-grid__item{
      margin-bottom: 40px;
    }
    .c-grid__img-container{
      height: 255px;
    }
    .c-grid__cap {
      font-size: 24px;
      line-height: 32px;
      margin: 50px 0 0 0;
    }
    .c-grid__text {
      font-size: 16px;
      line-height: 24px;
      margin: 15px 0 0 0;
    }
  }


  .post-sup-cap{
    font-size: 16px;
    line-height: 178%;
    padding-left: 30px;
    &:after{
      width: 22px;
    }
  }

  .s-share{
    padding-top: 0;
    margin-bottom: 50px;
  }

  .s-contact-form{
    padding-bottom: 40px;
  }
  .f-contacts{
    &__input{
      max-width: 235px;
      height: 48px;
      margin-bottom: 15px;
    }
    &__ta{
      height: 200px;
    }
  }

  .s-connect{
    padding-bottom: 140px;
  }

  .footer-form{
    flex-direction: column;
    &__label{
      margin-bottom: 40px;
    }
  }
  .foot-nav__contacts, .foot-nav__links, .foot-nav__copyright{
    max-width: 544px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
  }
  .foot-divider{
    margin: 80px auto 40px auto;
    &--sm{
      display: block;
      margin:  0px auto;
    }
  }
  .foot-nav{
    max-width: none;
    width: 100%;
    padding: 0;
    &__links{
    }
    &__links-col{
      margin-bottom: 40px;
      &:last-child{
        width: auto;
      }
    }
    &__contacts{
      padding: 40px 30px;
    }
    &__logo{
      display: none;
    }
    &__copyright{
      display: flex;
      flex-wrap: wrap;
      &__item:first-child{
        width: auto;
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 543px) {
  .content-holder, .head-nav, .foot-form-block{
    padding: 0 15px;
  }

  .btn--to-top{
    right: 20px;
    bottom: 20px;
  }
  .btn--vacancy{
    margin: 50px auto;
  }
  .btn--connect{
    max-width: none;
  }

  .logo{
    margin-left: 0;
    &__link{
      max-width: 138px;
    }
  }
  .side-nav{
    padding: 15px;
    top: -100%;
    right: 0;
    bottom: auto;
    &.nav-toggled{
      top: 0;
    }
    &__group, &__group-2, &__row{
      max-width: 320px;
    }
    &__group &__row{
      flex-direction: column;
      &--close{
        flex-direction: row;
      }
    }
    &__group{
      min-height: auto;
      padding-bottom: 35px;
    }
    &__group-2{
      flex-wrap: wrap;
      padding: 25px 0;
    }
    &__row{
      margin-bottom: 36px;
      &--lang{
        justify-content: space-between;
        margin-bottom: 0;

      }
    }

    &__cap--btn{
      position: relative;
      max-width: none;
      width: 100%;
      transition: color .2s linear 0s;
      cursor: pointer;
      &:after{
        display: block;
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 3px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #000000 transparent transparent transparent;
        transition: all .3s linear 0s;
      }
      &:hover{
        color: #000000;
      }
    }
    &__cap--btn.clicked{
      transition: color .2s linear 0s;
      color: #0166FF;
      &:after{
        transform: rotate(180deg);
        border-color: #0166FF transparent transparent transparent;
      }
    }
    &__links{
      display: none;
      &--lang{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
      }
    }
    &__link{
      display: block;
    }
    &__group &__link{
      margin: 8px 0 0 0;
      &:first-child{
        margin: 15px 0 0 0;
      }
    }
  }

  .page-header, .s-internationalization .page-header{
    font-size: 32px;
  }
  .section-header, .service-page-body .section-header,
  .s-info .section-header, .s-engagement .section-header, .s-jobs .section-header{
    font-size: 28px;
    line-height: 42px;
  }

  .sup-header, .block-header .sup-header{
    font-size: 12px;
    line-height: 1;
    margin: 0;
  }

  .text-block{
    font-size: 14px;
    &__list-cap{
       font-size: 16px;
       line-height: 28px;
     }
    &__list-item{
      font-size: 14px;
      line-height: 22px;
    }
  }

  .s-entrance, .s-starting{
    padding-top: 56px;
  }

  .block-header{
    padding: 0;
  }

  .s-entrance{
    min-height: auto;
    .text-block{
      .section-header{
        font-size: 28px;
        line-height: 117%;
      }
      &__text{
        font-size: 16px;
        line-height: 22px;
      }
    }

    .thumbs{
      &__row{
        flex-direction: column;
        margin: 0;
      }
      &__thumb{
        margin: 0 0 32px 0;
      }
      &__row .thumbs__thumb{
        &--mid, &--side{
          width: 100%;
        }
      }
    }
  }

  .s-overview{
    .illustration{
      margin-top: 50px;
      width: 100%;
      padding-right: 9.5%;
    }
  }

  .s-service{
    .content-holder--2{
      padding-bottom: 0;
    }
    .text-block{
      &__text{
        font-size: 16px;
        line-height: 28px;
      }
    }
    .c-grid{
      &__item{
        padding: 0;
        margin-bottom: 40px;
        min-height: auto;
        .btn--link{
          width: 200px;
          height: 50px;
          margin: 30px auto;
        }
      }
      &__cap{
        font-size: 22px;
        line-height: 24px;
        margin: 30px auto;
      }
    }
  }

  .s-starting{
    .illustration{
      margin: 20px 0 0 0;
      &__img {
        margin: 0;
        max-width: 80px;
      }
      &--puzzle{
        width: 114px;
        height: 142px;
        margin: 3px 0 -40px 0;
      }
      &__fragment{
        &--inno1{
          width: 80px;
          right: 19px;
          bottom: 58px;
        }
        &--inno2{
          width: 38px;
          bottom: 10px;
          right: 61px;
        }
        &--inno3{
          width: 10px;
          right: 85px;
          bottom: 22px;
        }
        &--inno4{
          width: 28px;
          right: 50px;
        }
        &--inno5{
          width: 54px;
        }

        &--inter1{
          width: 38px;
          bottom: -3px;
          right: 63px;
        }
        &--inter2{
          width: 46px;
          bottom: 50px;
          right: 19px;
        }
        &--inter3{
          width: 21px;
          bottom: 71px;
          right: 0;
        }
        &--inter4{
          width: 27px;
          right: 45px;
        }
        &--inter5{
          width: 48px;
          bottom: 19px;
          right: 1px;
        }
        &--inter6{
          width: 50px;
          bottom: 0;
          right: 0;
        }

        &--comm1{
          width: 34px;
          right: 74px;
          bottom: 95px;
        }
        &--comm2{
          width: 42px;
          right: 30px;
          bottom: 80px;
        }
        &--comm3{
          width: 35px;
          right: 18px;
          bottom: 67px;
        }
        &--comm4{
          width: 52px;
          right: 61px;
          bottom: 34px;
        }
        &--comm5{
          width: 53px;
          right: 0px;
          bottom: 30px;
        }
        &--comm6{
          width: 22px;
          right: 0;
          bottom: 12px;
        }

        &--intern1{
          width: 39px;
          right: 0px;
          bottom: 101px;
        }
        &--intern2{
          width: 78px;
          right: 22px;
          bottom: 62px;
        }
        &--intern3{
          width: 39px;
          right: 28px;
          bottom: 39px;
        }
        &--intern4{
          width: 35px;
          right: 0px;
          bottom: 66px;
        }
        &--intern5{
          width: 39px;
          right: 11px;
          bottom: 50px;
          transform: scale(1, 1);
        }
      }
    }
    .text-block{
      margin: 0 0 0 0;
      &__text{
        font-size: 16px;
        margin-top: 9px;
        line-height: 22px;
      }
    }

    &--post{
      .page-header{
        font-size: 32px;
      }
      .text-block__text{
        font-size: 17px;
        line-height: 133%;
      }
    }
  }

  .s-diagonal.s-jobs, .s-diagonal.s-engagement{
    .diagonal-block .illustration{
      order: 1;
      margin: 30px -15px 15px -15px;
    }
    .diagonal-block .text-block{
      order: 2;
    }
  }
  .diagonal-block{
    padding: 0;
    flex-direction: column;
    .illustration{
      position: initial;
      order: 2;
      width: calc(100% + 30px);
      max-width: none;
      margin: 8px -15px 0 -15px;
      img{
        width: 100%;
      }
    }
    .text-block{
      order: 1;
      box-shadow: none;
      padding: 0;
      min-height: auto;
      margin-top: 20px;
      &__cap{
        font-size: 14px;
      }
      &__text{
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 20px 0;
      }
      &__list-cap{
        font-size: 14px;
        line-height: 28px;
      }
      &__list-item{
        font-size: 14px;
      }
    }
    &--to-right{
      margin-top: 0;
    }
  }

  .s-info{
    padding-top: 80px;
    .section-header {
      margin-bottom: 30px;
    }
    .text-block{
      &__cap{
        font-size: 14px;
      }
      &__text{
        font-size: 14px;
        line-height: 22px;
      }
      &__list{
        margin: 25px 0;
      }
      &__list-item{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .labels--social{
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  .label--social{
    max-width: 290px;
    margin-bottom: 20px;
  }

  .s-interaction{
    min-height: auto;
    .content-holder{
      justify-content: flex-start;
      min-height: auto;
    }
  }

  .s-research{
    background: #ffffff;
  }

  .hmi-list{
    margin: 20px 0 0 0;
    &__item{
      margin: 70px 0 0 0;
      &:last-child{
        padding-bottom: 60px;
      }
    }
    .text-block{
      .hmi-list__item-num {
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        font-size: 20px;
      }
      &__cap{
        font-size: 20px;
        line-height: 28px;
        padding: 2px 0 0 48px;
      }
      &__text{
        font-size: 14px;
        line-height: 22px;
        margin-top: 20px;
      }
    }
    .illustration{
      margin-top: 25px;

    }
  }

  .s-testing {
    padding-bottom: 95px;
  }

  .s-jobs{
    padding-bottom: 70px;
  }

  .s-research, .s-hmi, .s-prototyping, .s-ergonomics, .s-testing{
    padding-top: 80px;
  }

  .s-about{
    .page-header {
      margin: 20px 0;
    }
    .illustration{
      padding-left: 0;
      &__img{
        max-width: none;
      }
    }
    &--contacts{
      .text-block{
        max-width: 290px;
      }
    }
  }

  .s-achieves{
    padding-bottom: 0;
    .labels{
      margin-top: 50px;
      justify-content: center;
    }
    .label{
      width: 290px;
      max-width: none;
      height: 194px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .s-team{
    .grid{
      max-height: 445px;
      &__cont{
        max-width: 290px;
        margin: 0 auto;
      }
    }
    .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      max-width: calc(100% - 5px);
      left: 0;
    }
  }
  .teammate {
    width: 250px;
    height: 167px;
    &__name{
      font-size: 18px;
      line-height: 22px;
    }
    &__pos{
      font-size: 14px;
      line-height: 28px;
    }
  }

  .s-customers .grid__cont{
    padding-bottom: 0;
  }

  .s-partners{
    .partner{
      max-width: 49%;
      &:nth-child(even){
        padding: 0 0 0 20px;
      }
      &:nth-child(odd){
        padding: 0 20px 0 0;
      }
    }
  }

  .s-stories {
    .content-holder{
      padding-bottom: 77px;
    }
    .section-header {
       margin: 39px auto;
    }
    .c-grid__item{
      padding: 0 0 25px 0;
      margin-bottom: 48px;
    }
    .c-grid__img-container{
      height: auto;
    }
    .c-grid__cap, .c-grid__text {
      padding: 0 16px 0 16px;
    }
    .c-grid__cap{
      font-size: 20px;
      line-height: 28px;
      margin: 28px 0 0 0;
    }
    .c-grid__text{
      font-size: 14px;
      line-height: 24px;
      margin: 20px 0 0 0;
    }
    .btn--link{
      width: 200px;
      height: 50px;
      font-size: 12px;
      line-height: 24px;
    }
  }

  .post-sup-cap{
    margin-bottom: 10px;
  }
  .s-post-illustration{
    padding: 25px 0;
  }
  .s-post{
    padding: 25px 0;
  }
  .post-p{
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 28px 0;
  }
  .post-h3{
    font-size: 24px;
    line-height: 28px;
  }
  .post-h4{
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 28px 0;
  }
  .post-pic{
    &--full{
      margin: 22px 0 49px 0;
    }
  }

  .s-share{
    .content-holder{
      flex-direction: column;
      align-items: center;
    }
  }
  .share-links{
    margin: 25px 0 0 0;
  }

  .s-contact-form{
    padding-top: 0;
    .content-holder{
      max-width: 320px;
    }
  }
  .f-contacts{
    &__input{
      max-width: none;
    }
    .btn--submit{
      width: 100%;
    }
  }

  .s-connect{
    padding: 10px 0 100px 0;
    .content-holder{
      max-width: 320px;
    }
    .side-left{
      margin-top: 50px;
    }
  }

  .s-privacy, .s-imprint{
    padding: 25px 0 50px 0;
  }

  footer.page-footer{
    padding: 47px 0 100px 0;
  }
  .footer-form{

    &__label{
      margin-bottom: 28px;
    }
    &__input {
      width: calc(100% - 92px);
      font-size: 14px;
      line-height: 16px;
      padding: 8px;
    }
    &__submit{
      width: 92px;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .foot-divider {
    margin: 46px auto 30px auto;
    &--sm{
      margin: 0px auto 0px auto;
    }
  }
  .foot-nav__contacts, .foot-nav__links, .foot-nav__copyright{
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .foot-nav{
    &__links{
      flex-direction: column;
    }
    &__link{
      font-size: 18px;
      line-height: 32px;
    }
    &__links-col {
      margin-bottom: 27px;
    }
    &__contacts{
      padding: 30px 15px;
    }
    &__contacts-item{
      font-size: 14px;
      line-height: 20px;
    }
    &__copyright{
      &__item{
        font-size: 14px;
        line-height: 16px;
        &:first-child{
          margin-bottom: 30px;
          width: 100%;
        }
      }
    }
  }
}