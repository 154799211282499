body.interaction-page-body .side-nav__cap--interaction{
  color: #0166FF;
}


.s-interaction{
  align-items: center;
  .content-holder{
    min-height: 360px;
  }
  .text-block{
    margin: 10px 0 0 0;
    &__text{
      margin-top: 60px;
    }
  }
  .illustration{
    &__img{
      max-width: 302px;
      margin: 0;
    }
  }
}

.s-innovation{
  .illustration{
    &__img{
      max-width: 305px;
    }
  }
}

.s-internationalization{
  .illustration{
    &__img{
      max-width: 308px;
    }
  }
}

.s-about{
  .text-block{
    &__text{
      color: #1D1D1F;
    }
  }
  .illustration{
    margin: 0 -150px 0 0;
    &__img{
      max-width: 640px;
    }
  }
  &--contacts{
    min-height: auto;
    .content-holder{
      margin-top: 80px;
    }
    .illustration{
      width: 60%;
    }
    .page-header{
      margin: 45px 0 62px 0;
    }
  }
}

.s-achieves{
  padding-bottom: 64px;
  .content-holder{
    flex-direction: column;
  }
  .illustration{
    max-width: 640px;
  }
  .labels{
    margin-top: 100px;
  }
}

.labels{
  display: flex;
  width: 100%;
  justify-content: space-between;
  &--social{
    margin-top: 50px;
  }
}

.label{
  background: #F5F5F7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &--tape{
    height: 175px;
    max-width: 263px;
    flex-direction: column;
    padding: 20px;
  }
  &--social{
    height: 121px;
    max-width: 249px;
    .label__img{
      width: auto;
      height: auto;
      max-height: 57px;
      max-width: 190px;
      mix-blend-mode: multiply;
    }
  }

  &__cap{
    font-weight: 900;
    font-size: 64px;
    line-height: 1;
    display: flex;
    text-align: center;
    color: #0166FF;
    margin-bottom: 5px;
  }
  &__text{
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #888888;
    max-width: 152px;
    min-height: 44px;
  }
}

.s-research{
  background: linear-gradient(to bottom, #f5f5f7 0%,#ffffff 14%,#ffffff 100%);
  .content-holder{
    //margin-top: 140px;
  }
  .text-block__list{
    margin: 22px 0 0 0;
  }
}

.s-hmi{
  background: linear-gradient(180deg, #FFFFFF 0%, #FBFBFD 100%);
  .content-holder{
    flex-direction: column;
  }
}

.hmi-list{
  width: 100%;
  &__item{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 80px 0;
    &:first-child{
    }
    .illustration{
      width: 100%;
      max-width: 555px;
    }
  }
  .text-block{
    max-width: 520px;
    padding-right: 20px;
    margin: 10px 0 0 0;
    &__cap{
      min-height: 42px;
      position: relative;
      padding: 7px 0 0 54px;
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      color: #0166FF;

    }
    &__text{
      margin: 20px 0 0 0;
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F;

    }
  }
  &__item-num{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 40px;
    background: rgba(1, 102, 255, 0.2);
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    color: #0166FF;
  }
}

.s-prototyping{
  .content-holder{
    //margin-top: 185px;
  }
}

.s-research, .s-hmi, .s-prototyping, .s-ergonomics,
.s-testing, .s-jobs, .s-engagement{
  padding-top: 70px;
}

.s-testing{
  padding-bottom: 120px;
}

.s-team{
  .grid{
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 435px;
    padding-bottom: 20px;
    margin-left: -30px;
    padding-left: 30px;
    width: auto;
  }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal{
    width: 100%;
    max-width: 650px;
    height: 19px;
    bottom: 0;
    left: calc(50% - 325px);
  }
  .mCS-rounded.mCSB_scrollTools .mCSB_draggerRail{
    background: #D8D8D8;
    height: 3px;
    margin: 8px 0;
    border-radius: 3px;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background: #0166FF;
    height: 17px;
    width: 17px;
  }
}
.teammate{
  width: 262px;
  height: 175px;
  margin: 0 30px 30px 0;
  position: relative;
  &__img{
    display: block;
    width: 100%;
    height: auto;
  }
  &__text{
    position: absolute;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    padding: 0 0 9px 11px;
  }
    &__name{
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
    &__pos{
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
    }
}

.s-jobs{
  padding-bottom: 100px;
}

.s-customers{
  padding-bottom: 115px;
  .content-holder--customers{
    background: #FFFFFF;
    max-width: none;
    margin-top: 84px;
    box-shadow: 0px -115px 80px 0 #F5F5F7, 0px 115px 0px 0px #F5F5F7;
  }
  .grid{
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -30px;
    width: calc(100% + 64px);
    &__cont{
      margin: auto;
      max-width: 1140px;
      overflow: hidden;
      padding: 39px 0 0 0;
    }
  }
  .customer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 32px 40px 32px;
    &__img{
      display: block;
      width: 100%;
      &--1, &--2{
        max-width: 117px;
      }
      &--3{
        max-width: 123px;
      }
      &--4, &--10, &--11, &--20{
        max-width: 180px;
      }
      &--5, &--6, &--9, &--16{
        max-width: 134px;
      }
      &--7, &--8{
        max-width: 157px;
      }
      &--12, &--13, &--15{
        max-width: 144px;
      }
      &--14{
        max-width: 191px;
      }
      &--17, &--18{
        max-width: 106px;
      }
      &--19{
        max-width: 62px;
      }
    }
  }
}

.s-partners{
  background: #FFFFFF;
  overflow: hidden;
  .text-block{
    &__text{
      margin-bottom: 28px;
    }
  }
  .partners-grid{
    margin-bottom: 53px;
  }
  .grid{
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% + 64px);
    margin: 0 -32px;
  }
  .partner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 190px;
    height: 57px;
    margin: 32px;
    &__img{
      display: block;
      height: auto;
      width: 100%;
      &--1{
        max-width: 160px;
      }
      &--2, &--4, &--5{
        max-width: 168px;
      }
      &--6{
        max-width: 123px;
      }
    }
  }
}

.s-engagement, .s-jobs{
  .section-header{
    font-size: 64px;
  }
}


.s-post-illustration{
  padding: 50px 0 25px 0;
  .illustration{
    overflow: hidden;
  }
}
.s-post, .s-share{
  padding: 50px 0 25px 0;
  .content-holder{
    max-width: 810px;
  }
}
.s-post{
  padding: 50px 0 25px 0;
  .content-holder{
    flex-direction: column;
  }
}
.post-sup-cap{
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #1D1D1F;
  text-align: left;
  padding-left: 43px;
  position: relative;
  margin-bottom: 25px;
  &:after{
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 30px;
    background-color: #0166FF;
    left: 0;
    top: 50%;
  }
}
.post-text-block{
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
}
.post-p{
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 28px 0;
  color: #1D1D1F;
}
.post-h3{
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #0166FF;
  text-align: left;
  margin: 0 0 28px 0;
}
.post-h4{
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1D1D1F;

  margin: 0 0 28px 0;
}
.post-link{
  font-size: inherit;
  line-height: inherit;
  color: #1D1D1F;
  display: inline-block;
  position: relative;
  transition: color .2s ease 0s;
  &:after{
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    display: block;
    bottom: 0;
    left: 0;
    background-color: #1D1D1F;
    transition: background-color .2s ease 0s, width .2s linear 0s;
  }
  &:hover{
    color: #0166FF;
    &:after{
      background-color: #0166FF;
    }
  }
}
.post-pic{
  margin: 0 0 0 0;
  display: inline-block;
  &--full{
    margin: 22px 0 49px 0;
  }
}
.post-list{
  margin-bottom: 28px;
  li{
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #1D1D1F;
    padding-left: 31px;
    position: relative;
    margin-bottom: 12px;
    &:after{
      display: block;
      position: absolute;
      width: auto;
      height: 28px;
      top: 0;
      left: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &--01{
    li:after{
      content: '\2014';
    }
  }
  &--02{
   li:after{
      content: url('../img/check-mark.svg');
    }
  }
}

.s-share{
  margin-bottom: 110px;
  .section-header{
    font-size: 64px;
    line-height: 1;
  }
  .content-holder{
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
  }
}
.share-links{
  display: flex;
  align-items: center;
  margin: 0 0 3px 60px;
}

.side-left{

  display: flex;
  max-width: 750px;
  width: 100%;
  flex-wrap: wrap;
}

.s-contact-form{
  padding: 37px 0 77px 0;
}
.f-contacts{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__input{
    width: 100%;
    max-width: 360px;
    height: 56px;
    padding: 0 16px;
    margin: 0 0 30px 0;
    background: #F5F5F7;
    border-radius: 4px;
    color: #6F6F6F;
    font-size: 16px;
    line-height: 19px;
    ::-webkit-input-placeholder { color: #6F6F6F; }
    ::-moz-placeholder          { color: #6F6F6F; }
    :-moz-placeholder           { color: #6F6F6F; }
    :-ms-input-placeholder      { color: #6F6F6F; }
  }
  &__ta{
    width: 100%;
    height: 288px;
    border: none;
    background: #F5F5F7;
    resize: none;
    margin-bottom: 30px;
    padding: 16px;
    color: #6F6F6F;
    font-size: 16px;
    line-height: 19px;
    outline: none;
  }

  .btn--submit{
    width: 262px;
    height: 56px;
    border-radius: 4px;
  }
}

.s-connect{
  padding: 60px 0 230px 0;
  .content-holder{
    flex-direction: column;
  }
  .side-left{
    margin-top: 78px;
    justify-content: space-between;
  }
}


.s-privacy{
  padding: 50px 0 140px 0;
  .content-holder{
    flex-direction: column;
  }
}


.s-imprint{
  padding: 47px 0 140px 0;
}