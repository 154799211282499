@charset "UTF-8";
* {
  box-sizing: border-box; }

body {
  background: #F2F2F2;
  position: relative;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  overflow-x: hidden; }

a, a:hover, a:link, a:visited, a:focus {
  text-decoration: none;
  cursor: pointer;
  outline: none; }

input {
  border: none;
  outline: none;
  padding: 0;
  margin: 0; }

button {
  border: none;
  outline: none;
  box-shadow: none; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-align: center; }

p {
  margin: 0; }

img {
  display: block;
  width: 100%;
  height: auto; }

ul, li {
  padding: 0;
  margin: 0;
  list-style-type: none; }

body.doc-body.side-toggled {
  overflow: hidden; }

.content-wrapper {
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  position: relative; }

.content-holder, .head-nav, .foot-nav, .foot-form-block {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px; }

.s-to-right {
  display: flex;
  justify-content: flex-end; }

.content-holder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .content-holder--info {
    max-width: 810px; }

.section-header {
  max-width: 1140px;
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  color: #0166FF;
  text-align: left; }
  .section-header--centred {
    text-align: center;
    line-height: 55px; }

.page-header {
  font-weight: bold;
  font-size: 72px;
  line-height: 54px;
  text-align: left;
  color: #0166FF; }

.service-page-body .section-header {
  font-size: 64px; }

.sup-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: rgba(1, 102, 255, 0.49664); }

.fc-blue {
  color: #0166FF; }

.fw-bold {
  font-weight: bold; }

.underlined {
  position: relative; }
  .underlined:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    display: block;
    bottom: 0;
    left: 0;
    background-color: #0166ff00;
    transition: background-color .2s ease 0s; }

input.error {
  box-shadow: 0 0 0 1px #ff0000;
  color: #ff0000; }
  input.error::-webkit-input-placeholder {
    color: #ff0000; }
  input.error::-moz-placeholder {
    color: #ff0000; }
  input.error:-moz-placeholder {
    color: #ff0000; }
  input.error:-ms-input-placeholder {
    color: #ff0000; }

.btn {
  cursor: pointer;
  display: flex; }
  .btn__line {
    height: 2px;
    width: 28px;
    background-color: #000000;
    transition: background-color .2s linear 0s; }
  .btn--burger {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    padding: 6px 0; }
  .btn--close {
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    position: relative; }
    .btn--close .btn__line {
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 14px);
      transform: rotate(45deg); }
    .btn--close .btn__line:last-child {
      transform: rotate(-45deg); }
  .btn--link {
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-transform: uppercase;
    color: #0166FF;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #0166FF;
    transition: color 0.2s linear 0s, background-color 0.2s linear 0s, transform 0.2s cubic-bezier(0.27, 0.13, 0.42, 1.36) 0s; }
    .btn--link:active, .btn--link:focus, .btn--link:hover {
      color: #fff;
      background: #0166FF;
      transform: translateY(-5px); }
      .btn--link:active::before, .btn--link:focus::before, .btn--link:hover::before {
        opacity: 1;
        transform: translateY(5px); }
    .btn--link:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 90%;
      left: 5%;
      height: 30px;
      width: 100%;
      opacity: 0;
      background: radial-gradient(ellipse at top, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 80%);
      transition-duration: 0.3s;
      transition-property: transform, opacity; }
  .btn--submit {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .2s linear 0s, color .2s linear 0s;
    background-color: #0166FF;
    color: #FFFFFF; }
    .btn--submit:hover {
      background-color: #042999; }
  .btn--vacancy {
    width: 100%;
    max-width: 515px;
    height: 72px;
    background-color: #FFFFFF;
    text-transform: none;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-top: 50px; }
    .btn--vacancy[disabled] {
      border: #F5F5F7;
      background: #F5F5F7;
      color: #888888;
      cursor: initial; }
      .btn--vacancy[disabled]:hover {
        transform: none; }
        .btn--vacancy[disabled]:hover:before {
          display: none; }
  .btn--to-top {
    display: block;
    position: fixed;
    border-radius: 40px;
    overflow: hidden;
    bottom: 40px;
    right: 40px; }
    .btn--to-top img {
      width: 52px; }
  .btn--share {
    width: 44px;
    height: 44px;
    background: #F5F5F7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0166FF;
    margin: 0 30px 0 0;
    font-size: 24px;
    transition: background-color .3s ease 0s, color .3s ease 0s; }
    .btn--share:last-child {
      margin: 0; }
    .btn--share:hover {
      background-color: #0166FF;
      color: #FFFFFF; }
  .btn--connect {
    background: #F5F5F7;
    border-radius: 4px;
    width: 100%;
    max-width: 360px;
    height: 64px;
    font-size: 18px;
    line-height: 22px;
    color: #1D1D1F;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    padding-left: 16px;
    transition: background-color .3s ease 0s, color .3s ease 0s;
    margin-bottom: 30px; }
    .btn--connect i.fab {
      font-size: 36px;
      color: #0166FF;
      line-height: 32px;
      margin-right: 16px;
      transition: color .3s ease 0s; }
    .btn--connect:hover {
      background-color: #ECECED; }

.btn:hover .btn__line {
  background-color: #888888; }

.text-link {
  transition: color .2s ease 0s; }
  .text-link--grey:hover {
    color: #333333; }
  .text-link--blue:hover {
    color: #042999; }

.text-block {
  max-width: 50%;
  width: 100%; }
  .text-block__list {
    margin: 53px 0 0 0;
    max-width: 540px; }
  .text-block__list-cap {
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1F; }
  .text-block__list-item {
    padding-left: 31px;
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1F;
    font-weight: bold;
    position: relative;
    margin: 22px 0; }
    .text-block__list-item:before {
      content: url("../img/check-mark.svg");
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      top: 1px;
      left: 0; }
    .text-block__list-item--m-dash:before {
      content: '\2014';
      display: block;
      position: absolute;
      width: auto;
      height: 28px;
      top: 0;
      left: 0; }

.c-grid {
  width: 100%; }
  .c-grid__item-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
  .c-grid__item {
    display: flex;
    flex-direction: column;
    width: 521px; }

.grid {
  display: flex;
  width: 100%; }
  .grid__cont {
    width: 100%; }

.block-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0; }
  .block-header .sup-header {
    text-align: left;
    margin: 0 0 0 3px; }

.s-entrance, .s-starting {
  padding-top: 158px; }

.s-starting {
  display: flex;
  position: relative;
  padding-bottom: 20px;
  min-height: 768px; }
  .s-starting .text-block {
    position: relative;
    z-index: 2; }
    .s-starting .text-block__text {
      font-size: 24px;
      line-height: 32px;
      color: #1D1D1F; }
  .s-starting .illustration {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .s-starting .illustration--puzzle {
      width: 304px;
      height: 302px;
      position: relative; }
    .s-starting .illustration__fragment {
      position: absolute;
      width: auto;
      height: auto; }
      .s-starting .illustration__fragment--inno1 {
        width: 238px;
        top: 0;
        left: 0; }
      .s-starting .illustration__fragment--inno3 {
        width: 31px;
        left: 14px;
        top: 118px; }
      .s-starting .illustration__fragment--inno4 {
        width: 85px;
        left: 62px;
        bottom: 0; }
      .s-starting .illustration__fragment--inno2 {
        width: 115px;
        bottom: 24px;
        left: 0; }
      .s-starting .illustration__fragment--inno5 {
        width: 162px;
        bottom: 0;
        right: 10px; }
      .s-starting .illustration__fragment--inter1 {
        width: 112px;
        right: 191px;
        bottom: -10px; }
      .s-starting .illustration__fragment--inter4 {
        width: 79px;
        right: 139px;
        bottom: -9px; }
      .s-starting .illustration__fragment--inter2 {
        width: 138px;
        right: 58px;
        bottom: 145px;
        z-index: 2; }
      .s-starting .illustration__fragment--inter3 {
        width: 62px;
        right: 0;
        bottom: 207px; }
      .s-starting .illustration__fragment--inter5 {
        width: 143px;
        right: 3px;
        bottom: 50px; }
      .s-starting .illustration__fragment--inter6 {
        width: 150px;
        right: 0;
        bottom: -6px; }
      .s-starting .illustration__fragment--comm1 {
        width: 101px;
        right: 203px;
        bottom: 175px; }
      .s-starting .illustration__fragment--comm2 {
        width: 126px;
        right: 65px;
        bottom: 131px; }
      .s-starting .illustration__fragment--comm3 {
        width: 105px;
        right: 39px;
        bottom: 196px; }
      .s-starting .illustration__fragment--comm4 {
        width: 156px;
        right: 148px;
        bottom: -2px; }
      .s-starting .illustration__fragment--comm5 {
        width: 160px;
        right: 3px;
        bottom: 29px; }
      .s-starting .illustration__fragment--comm6 {
        width: 66px;
        right: 4px;
        bottom: -5px; }
      .s-starting .illustration__fragment--intern1 {
        width: 115px;
        right: 3px;
        bottom: 178px; }
      .s-starting .illustration__fragment--intern2 {
        width: 236px;
        right: 66px;
        bottom: 54px; }
      .s-starting .illustration__fragment--intern3 {
        width: 117px;
        right: 84px;
        bottom: -10px; }
      .s-starting .illustration__fragment--intern4 {
        width: 106px;
        right: 0px;
        bottom: 71px; }
      .s-starting .illustration__fragment--intern5 {
        width: 116px;
        right: 32px;
        bottom: 21px; }
  .s-starting--service {
    min-height: auto;
    padding-bottom: 80px; }
    .s-starting--service .content-holder {
      align-items: flex-end;
      margin-top: 125px; }
    .s-starting--service .text-block {
      max-width: none; }
      .s-starting--service .text-block__text {
        margin-top: 63px;
        max-width: 50%; }
  .s-starting--post {
    min-height: auto;
    padding-bottom: 25px; }
    .s-starting--post .content-holder {
      margin-top: 75px; }
    .s-starting--post .page-header {
      line-height: 80px; }
    .s-starting--post .text-block {
      width: auto;
      max-width: 945px; }
      .s-starting--post .text-block__text {
        margin-top: 25px; }

.s-diagonal {
  display: flex;
  overflow: hidden; }
  .s-diagonal:active, .s-diagonal:focus {
    outline: none; }
  .s-diagonal .content-holder {
    flex-direction: column; }

.diagonal-block {
  position: relative;
  padding-top: 186px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%; }
  .diagonal-block .illustration {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 750px;
    overflow: hidden;
    background: #f1f1f1; }
  .diagonal-block .text-block {
    max-width: 570px;
    position: relative;
    background: #ffffff;
    padding: 52px 0 52px 52px;
    border-radius: 12px 0 0 0;
    min-height: 389px;
    box-shadow: -60px -50px 70px -80px rgba(127, 127, 127, 0.532); }
    .diagonal-block .text-block__cap {
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      color: #1D1D1F;
      margin-bottom: 30px; }
      .diagonal-block .text-block__cap--m-t {
        margin: 30px 0; }
    .diagonal-block .text-block__text {
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F;
      margin: 0 0 15px 0; }
    .diagonal-block .text-block__list-cap {
      font-size: 18px; }
    .diagonal-block .text-block__list-item {
      font-size: 18px; }
  .diagonal-block--to-right {
    justify-content: flex-start; }
    .diagonal-block--to-right .illustration {
      left: auto;
      right: 0; }
    .diagonal-block--to-right .text-block {
      box-shadow: 60px -50px 70px -80px rgba(127, 127, 127, 0.532);
      padding: 52px 45px 52px 0;
      border-radius: 0 12px 0 0; }

.s-info {
  padding: 120px 0 33px 0; }
  .s-info .content-holder {
    flex-direction: column; }
  .s-info .section-header {
    font-size: 64px;
    margin-bottom: 50px; }
  .s-info .text-block {
    max-width: none; }
    .s-info .text-block__text {
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F; }
    .s-info .text-block__list {
      max-width: 596px;
      margin: 50px 0 25px 0; }
    .s-info .text-block__list-item {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F; }

.multi-wrapper {
  padding-bottom: 68px; }

div.styles {
  display: flex;
  padding: 180px 0; }
  div.styles .example {
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    width: 1000px;
    margin: auto; }
  div.styles .content-holder {
    max-width: 750px;
    flex-direction: column; }
  div.styles .fc-blue {
    width: 200px; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: "fa";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold; }

.zoomOut {
  transform: scale3d(1.3, 1.3, 1.3);
  visibility: visible !important; }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

header.head {
  width: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  transition: min-height .2s linear 0s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 158px;
  z-index: 9; }

body.home-page-body header.head.fixed, header.head.fixed {
  background: rgba(255, 255, 255, 0.97);
  z-index: 8;
  min-height: 88px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1); }

.head-nav {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.logo {
  font-size: 28px;
  font-weight: bold; }
  .logo__link {
    max-width: 184px;
    display: block; }
    .logo__link img {
      width: 100%;
      height: auto; }

.burg-line {
  height: 2px;
  width: 28px;
  background-color: #000000;
  transition: background-color .2s linear 0s; }

.btn--burger:hover .burg-line {
  background-color: #888888; }

.s-entrance {
  flex-direction: column; }
  .s-entrance .content-holder {
    display: flex; }
  .s-entrance .text-block {
    margin-top: 130px;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .s-entrance .text-block .section-header {
      max-width: 944px;
      text-align: center; }
    .s-entrance .text-block__text {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: #888888;
      max-width: 500px;
      text-align: center;
      margin-top: 34px; }
  .s-entrance .thumbs {
    margin: 108px auto -60px auto;
    max-width: 1440px; }
    .s-entrance .thumbs__row {
      display: flex; }
      .s-entrance .thumbs__row--top {
        align-items: flex-end;
        margin-bottom: 32px; }
        .s-entrance .thumbs__row--top .thumbs__thumb--mid {
          width: 726px; }
        .s-entrance .thumbs__row--top .thumbs__thumb--side {
          width: 325px; }
      .s-entrance .thumbs__row--bot {
        align-items: flex-start; }
        .s-entrance .thumbs__row--bot .thumbs__thumb--mid {
          width: 476px; }
        .s-entrance .thumbs__row--bot .thumbs__thumb--side {
          width: 450px; }
    .s-entrance .thumbs__thumb {
      overflow: hidden;
      position: relative;
      transition: box-shadow .4s ease 0s; }
      .s-entrance .thumbs__thumb--mid {
        margin: 0 32px; }
    .s-entrance .thumbs__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000000;
      opacity: 0;
      transition: opacity .4s ease .1s; }
      .s-entrance .thumbs__overlay:hover {
        opacity: 0.15; }

.s-overview {
  background: linear-gradient(180deg, #F5F5F7 2.6%, rgba(245, 245, 247, 0.0001) 100%);
  padding-top: 60px; }
  .s-overview .content-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px; }
  .s-overview .text-block {
    margin-top: 138px; }
  .s-overview .illustration {
    width: 458px;
    margin-top: 140px; }

.s-service .content-holder--1 {
  flex-direction: column;
  padding-bottom: 40px; }

.s-service .content-holder--2 {
  padding-bottom: 112px; }

.s-service .text-block {
  max-width: 750px;
  margin: 140px auto 0 auto; }
  .s-service .text-block__text {
    margin: 55px 0 0 0;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1F; }

.s-service .illustration {
  margin: 95px auto 0 auto;
  width: 100%;
  max-width: 860px; }

.s-service .c-grid {
  margin: 100px 0 0 0; }
  .s-service .c-grid__item {
    padding: 32px;
    margin-bottom: 28px;
    min-height: 442px;
    justify-content: space-between; }
  .s-service .c-grid__img {
    width: 110px;
    margin: 0 auto; }
  .s-service .c-grid__cap {
    margin: 30px 0;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #1D1D1F;
    text-align: center; }
  .s-service .c-grid__text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1D1D1F; }
  .s-service .c-grid__item .btn--link {
    margin: 10px auto;
    width: 224px;
    height: 56px; }

.service-tape {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0 auto; }
  .service-tape__item {
    width: 100%;
    max-width: 33%;
    flex-shrink: 0;
    overflow: hidden; }
    .service-tape__item:last-child {
      margin: 0 0 0 0; }

.s-stories {
  background: linear-gradient(180deg, #F5F5F7 2.6%, rgba(245, 245, 247, 0.0001) 100%); }
  .s-stories--page {
    background: #FFFFFF;
    padding-top: 60px; }
  .s-stories .content-holder {
    flex-direction: column;
    padding-bottom: 112px; }
  .s-stories .section-header {
    margin: 102px auto; }
  .s-stories .c-grid__img {
    transition: transform .2s linear 0s; }
  .s-stories .c-grid__item-wrapper {
    transition: height .3s linear 0s; }
  .s-stories .c-grid__item {
    border-radius: 7px;
    background-color: #ffffff;
    margin-bottom: 88px;
    padding: 0 0 30px 0;
    box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.131693); }
    .s-stories .c-grid__item:hover .c-grid__img {
      transform: scale(1.2); }
  .s-stories .c-grid__img-container {
    width: 100%;
    height: 255px;
    overflow: hidden;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; }
  .s-stories .c-grid__cap, .s-stories .c-grid__text {
    padding: 0 50px 0 30px; }
  .s-stories .c-grid__cap {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1D1D1F;
    margin: 50px 0 0 0; }
  .s-stories .c-grid__text {
    font-size: 16px;
    line-height: 24px;
    color: #888888;
    margin: 15px 0 0 0;
    min-height: 82px; }
  .s-stories .btn--link {
    width: 360px;
    height: 72px;
    margin: 17px auto 0 auto; }

body.interaction-page-body .side-nav__cap--interaction {
  color: #0166FF; }

.s-interaction {
  align-items: center; }
  .s-interaction .content-holder {
    min-height: 360px; }
  .s-interaction .text-block {
    margin: 10px 0 0 0; }
    .s-interaction .text-block__text {
      margin-top: 60px; }
  .s-interaction .illustration__img {
    max-width: 302px;
    margin: 0; }

.s-innovation .illustration__img {
  max-width: 305px; }

.s-internationalization .illustration__img {
  max-width: 308px; }

.s-about .text-block__text {
  color: #1D1D1F; }

.s-about .illustration {
  margin: 0 -150px 0 0; }
  .s-about .illustration__img {
    max-width: 640px; }

.s-about--contacts {
  min-height: auto; }
  .s-about--contacts .content-holder {
    margin-top: 80px; }
  .s-about--contacts .illustration {
    width: 60%; }
  .s-about--contacts .page-header {
    margin: 45px 0 62px 0; }

.s-achieves {
  padding-bottom: 64px; }
  .s-achieves .content-holder {
    flex-direction: column; }
  .s-achieves .illustration {
    max-width: 640px; }
  .s-achieves .labels {
    margin-top: 100px; }

.labels {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .labels--social {
    margin-top: 50px; }

.label {
  background: #F5F5F7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .label--tape {
    height: 175px;
    max-width: 263px;
    flex-direction: column;
    padding: 20px; }
  .label--social {
    height: 121px;
    max-width: 249px; }
    .label--social .label__img {
      width: auto;
      height: auto;
      max-height: 57px;
      max-width: 190px;
      mix-blend-mode: multiply; }
  .label__cap {
    font-weight: 900;
    font-size: 64px;
    line-height: 1;
    display: flex;
    text-align: center;
    color: #0166FF;
    margin-bottom: 5px; }
  .label__text {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #888888;
    max-width: 152px;
    min-height: 44px; }

.s-research {
  background: linear-gradient(to bottom, #f5f5f7 0%, #ffffff 14%, #ffffff 100%); }
  .s-research .text-block__list {
    margin: 22px 0 0 0; }

.s-hmi {
  background: linear-gradient(180deg, #FFFFFF 0%, #FBFBFD 100%); }
  .s-hmi .content-holder {
    flex-direction: column; }

.hmi-list {
  width: 100%; }
  .hmi-list__item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 80px 0; }
    .hmi-list__item .illustration {
      width: 100%;
      max-width: 555px; }
  .hmi-list .text-block {
    max-width: 520px;
    padding-right: 20px;
    margin: 10px 0 0 0; }
    .hmi-list .text-block__cap {
      min-height: 42px;
      position: relative;
      padding: 7px 0 0 54px;
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      color: #0166FF; }
    .hmi-list .text-block__text {
      margin: 20px 0 0 0;
      font-size: 18px;
      line-height: 28px;
      color: #1D1D1F; }
  .hmi-list__item-num {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 40px;
    background: rgba(1, 102, 255, 0.2);
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    color: #0166FF; }

.s-research, .s-hmi, .s-prototyping, .s-ergonomics,
.s-testing, .s-jobs, .s-engagement {
  padding-top: 70px; }

.s-testing {
  padding-bottom: 120px; }

.s-team .grid {
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 435px;
  padding-bottom: 20px;
  margin-left: -30px;
  padding-left: 30px;
  width: auto; }

.s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: 100%;
  max-width: 650px;
  height: 19px;
  bottom: 0;
  left: calc(50% - 325px); }

.s-team .mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background: #D8D8D8;
  height: 3px;
  margin: 8px 0;
  border-radius: 3px; }

.s-team .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: #0166FF;
  height: 17px;
  width: 17px; }

.teammate {
  width: 262px;
  height: 175px;
  margin: 0 30px 30px 0;
  position: relative; }
  .teammate__img {
    display: block;
    width: 100%;
    height: auto; }
  .teammate__text {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    padding: 0 0 9px 11px; }
  .teammate__name {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px; }
  .teammate__pos {
    font-weight: normal;
    font-size: 14px;
    line-height: 28px; }

.s-jobs {
  padding-bottom: 100px; }

.s-customers {
  padding-bottom: 115px; }
  .s-customers .content-holder--customers {
    background: #FFFFFF;
    max-width: none;
    margin-top: 84px;
    box-shadow: 0px -115px 80px 0 #F5F5F7, 0px 115px 0px 0px #F5F5F7; }
  .s-customers .grid {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -30px;
    width: calc(100% + 64px); }
    .s-customers .grid__cont {
      margin: auto;
      max-width: 1140px;
      overflow: hidden;
      padding: 39px 0 0 0; }
  .s-customers .customer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 32px 40px 32px; }
    .s-customers .customer__img {
      display: block;
      width: 100%; }
      .s-customers .customer__img--1, .s-customers .customer__img--2 {
        max-width: 117px; }
      .s-customers .customer__img--3 {
        max-width: 123px; }
      .s-customers .customer__img--4, .s-customers .customer__img--10, .s-customers .customer__img--11, .s-customers .customer__img--20 {
        max-width: 180px; }
      .s-customers .customer__img--5, .s-customers .customer__img--6, .s-customers .customer__img--9, .s-customers .customer__img--16 {
        max-width: 134px; }
      .s-customers .customer__img--7, .s-customers .customer__img--8 {
        max-width: 157px; }
      .s-customers .customer__img--12, .s-customers .customer__img--13, .s-customers .customer__img--15 {
        max-width: 144px; }
      .s-customers .customer__img--14 {
        max-width: 191px; }
      .s-customers .customer__img--17, .s-customers .customer__img--18 {
        max-width: 106px; }
      .s-customers .customer__img--19 {
        max-width: 62px; }

.s-partners {
  background: #FFFFFF;
  overflow: hidden; }
  .s-partners .text-block__text {
    margin-bottom: 28px; }
  .s-partners .partners-grid {
    margin-bottom: 53px; }
  .s-partners .grid {
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% + 64px);
    margin: 0 -32px; }
  .s-partners .partner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 190px;
    height: 57px;
    margin: 32px; }
    .s-partners .partner__img {
      display: block;
      height: auto;
      width: 100%; }
      .s-partners .partner__img--1 {
        max-width: 160px; }
      .s-partners .partner__img--2, .s-partners .partner__img--4, .s-partners .partner__img--5 {
        max-width: 168px; }
      .s-partners .partner__img--6 {
        max-width: 123px; }

.s-engagement .section-header, .s-jobs .section-header {
  font-size: 64px; }

.s-post-illustration {
  padding: 50px 0 25px 0; }
  .s-post-illustration .illustration {
    overflow: hidden; }

.s-post, .s-share {
  padding: 50px 0 25px 0; }
  .s-post .content-holder, .s-share .content-holder {
    max-width: 810px; }

.s-post {
  padding: 50px 0 25px 0; }
  .s-post .content-holder {
    flex-direction: column; }

.post-sup-cap {
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #1D1D1F;
  text-align: left;
  padding-left: 43px;
  position: relative;
  margin-bottom: 25px; }
  .post-sup-cap:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 30px;
    background-color: #0166FF;
    left: 0;
    top: 50%; }

.post-text-block {
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0; }

.post-p {
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 28px 0;
  color: #1D1D1F; }

.post-h3 {
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #0166FF;
  text-align: left;
  margin: 0 0 28px 0; }

.post-h4 {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1D1D1F;
  margin: 0 0 28px 0; }

.post-link {
  font-size: inherit;
  line-height: inherit;
  color: #1D1D1F;
  display: inline-block;
  position: relative;
  transition: color .2s ease 0s; }
  .post-link:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    display: block;
    bottom: 0;
    left: 0;
    background-color: #1D1D1F;
    transition: background-color .2s ease 0s, width .2s linear 0s; }
  .post-link:hover {
    color: #0166FF; }
    .post-link:hover:after {
      background-color: #0166FF; }

.post-pic {
  margin: 0 0 0 0;
  display: inline-block; }
  .post-pic--full {
    margin: 22px 0 49px 0; }

.post-list {
  margin-bottom: 28px; }
  .post-list li {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #1D1D1F;
    padding-left: 31px;
    position: relative;
    margin-bottom: 12px; }
    .post-list li:after {
      display: block;
      position: absolute;
      width: auto;
      height: 28px;
      top: 0;
      left: 0; }
    .post-list li:last-child {
      margin-bottom: 0; }
  .post-list--01 li:after {
    content: '\2014'; }
  .post-list--02 li:after {
    content: url("../img/check-mark.svg"); }

.s-share {
  margin-bottom: 110px; }
  .s-share .section-header {
    font-size: 64px;
    line-height: 1; }
  .s-share .content-holder {
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row; }

.share-links {
  display: flex;
  align-items: center;
  margin: 0 0 3px 60px; }

.side-left {
  display: flex;
  max-width: 750px;
  width: 100%;
  flex-wrap: wrap; }

.s-contact-form {
  padding: 37px 0 77px 0; }

.f-contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .f-contacts__input {
    width: 100%;
    max-width: 360px;
    height: 56px;
    padding: 0 16px;
    margin: 0 0 30px 0;
    background: #F5F5F7;
    border-radius: 4px;
    color: #6F6F6F;
    font-size: 16px;
    line-height: 19px; }
    .f-contacts__input ::-webkit-input-placeholder {
      color: #6F6F6F; }
    .f-contacts__input ::-moz-placeholder {
      color: #6F6F6F; }
    .f-contacts__input :-moz-placeholder {
      color: #6F6F6F; }
    .f-contacts__input :-ms-input-placeholder {
      color: #6F6F6F; }
  .f-contacts__ta {
    width: 100%;
    height: 288px;
    border: none;
    background: #F5F5F7;
    resize: none;
    margin-bottom: 30px;
    padding: 16px;
    color: #6F6F6F;
    font-size: 16px;
    line-height: 19px;
    outline: none; }
  .f-contacts .btn--submit {
    width: 262px;
    height: 56px;
    border-radius: 4px; }

.s-connect {
  padding: 60px 0 230px 0; }
  .s-connect .content-holder {
    flex-direction: column; }
  .s-connect .side-left {
    margin-top: 78px;
    justify-content: space-between; }

.s-privacy {
  padding: 50px 0 140px 0; }
  .s-privacy .content-holder {
    flex-direction: column; }

.s-imprint {
  padding: 47px 0 140px 0; }

.sidebar-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 9; }

.side-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end; }

.side-nav {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -100%;
  left: auto;
  width: 100%;
  height: 100%;
  max-width: 720px;
  background: #FFFFFF;
  box-shadow: 0px 2px 64px rgba(0, 0, 0, 0.173241);
  padding: 30px 30px 0 82px;
  transition: right .3s linear 0s, top .3s linear 0s;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10; }
  .side-nav__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px; }
    .side-nav__row--close {
      justify-content: flex-end;
      margin-bottom: 25px; }
    .side-nav__row--lang {
      margin-bottom: 0; }
  .side-nav__group .side-nav__row:last-child {
    margin-bottom: 0; }
  .side-nav__group {
    margin: 0;
    padding-bottom: 0;
    min-height: 640px; }
  .side-nav__group-2 {
    display: flex;
    border-top: 1px solid #d1d1d1;
    padding: 35px 0;
    justify-content: flex-start;
    align-items: baseline; }
  .side-nav__cap {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    width: 100%;
    max-width: 180px;
    margin-right: 15px;
    user-select: none;
    transition: color .2s linear 0s; }
    .side-nav__cap--link {
      width: auto;
      max-width: none;
      line-height: 24px;
      margin: 0 42px 0 0; }
    .side-nav__cap:hover {
      color: #0166FF; }
  .side-nav__links--lang {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .side-nav__links--lang .lang-divider {
      line-height: 24px;
      margin: 0 5px;
      font-size: 14px; }
  .side-nav__link {
    font-size: 14px;
    display: block;
    color: #6F6F6F;
    line-height: 24px;
    position: relative;
    transition: color .2s linear 0s; }
    .side-nav__link:hover {
      color: #0166FF; }
  .side-nav .lang-link {
    position: relative;
    display: inline-block;
    line-height: 24px; }
    .side-nav .lang-link--selected {
      color: #000000; }
      .side-nav .lang-link--selected:after {
        bottom: 0;
        background-color: #0166FF;
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        display: block;
        left: 0; }

.side-nav.nav-toggled {
  right: 0; }

.sub-nav-wrapper {
  box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.sub-nav {
  position: relative; }
  .sub-nav__arrow {
    position: absolute; }
  .sub-nav__group {
    min-height: 40px;
    position: relative;
    display: flex; }
  .sub-nav__link {
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #888888;
    padding: 0 28px;
    transition: color .2s linear 0s; }
    .sub-nav__link:hover {
      color: #1D1D1F; }
  .sub-nav__item--current .sub-nav__link {
    color: #1D1D1F; }
    .sub-nav__item--current .sub-nav__link:hover {
      color: #1D1D1F; }
  .sub-nav .magic-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background: #1D1D1F; }

footer.page-footer {
  background: #F5F5F7;
  padding: 88px 0 100px 0; }

.footer-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .footer-form__label {
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    font-weight: bold; }
  .footer-form__input-wrapper {
    display: flex;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.0379152);
    width: 100%;
    max-width: 530px; }
  .footer-form__input, .footer-form__submit {
    height: 48px; }
  .footer-form__input {
    background: #FFFFFF;
    width: calc(100% - 155px);
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px; }
  .footer-form__submit {
    width: 155px;
    border-radius: 0 4px 4px 0; }

.foot-divider {
  height: 1px;
  border: none;
  background: #D1D1D1;
  display: block;
  margin: 84px auto 75px auto;
  width: 100%; }
  .foot-divider--sm {
    display: none; }

.foot-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  .foot-nav__links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 50%;
    width: 100%; }
  .foot-nav__links-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 49%; }
  .foot-nav__link {
    color: #0166FF;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    width: 100%;
    max-width: 200px; }
  .foot-nav__contacts {
    width: 100%;
    max-width: calc(50% - 70px);
    padding: 5px 15px; }
  .foot-nav__contacts-item {
    font-size: 16px;
    line-height: 24px;
    color: #6F6F6F; }
    .foot-nav__contacts-item--cap {
      font-size: 16px;
      line-height: 24px;
      color: #000000; }
    .foot-nav__contacts-item--link {
      font-size: 16px;
      line-height: 26px; }
  .foot-nav__logo {
    width: 70px;
    margin: 20px 0 0 0; }
  .foot-nav__copyright {
    width: 100%;
    margin: 54px 0 0 0; }
    .foot-nav__copyright__item {
      color: #6F6F6F;
      font-size: 16px;
      margin: 0 45px 0 0; }

@media screen and (max-width: 1439px) {
  .s-about .content-holder {
    align-items: center; }
  .s-about .text-block {
    margin: 0; }
  .s-about .illustration {
    margin: 0;
    padding-left: 20px; } }

@media screen and (max-width: 1199px) {
  .content-holder, .head-nav, .foot-nav, .foot-form-block {
    padding: 0 26px;
    max-width: 940px; }
  .content-holder--info {
    max-width: 750px; }
  .page-header {
    font-size: 64px; }
  .service-page-body .section-header {
    font-size: 48px; }
  .s-entrance .text-block {
    margin-top: 50px; }
    .s-entrance .text-block .section-header {
      font-size: 42px;
      line-height: 117%; }
    .s-entrance .text-block__text {
      margin-top: 45px;
      font-size: 22px; }
  .s-entrance .thumbs {
    margin-top: 80px; }
  .s-starting .text-block {
    padding: 0 0 50px 0; }
    .s-starting .text-block__text {
      font-size: 20px;
      line-height: 28px; }
  .s-starting .illustration {
    width: 50%; }
    .s-starting .illustration__img {
      margin: 0; }
    .s-starting .illustration--puzzle {
      width: 304px;
      height: 302px; }
  .s-starting--service .text-block {
    padding: 0; }
  .s-info .section-header, .s-engagement .section-header, .s-jobs .section-header {
    font-size: 48px; }
  .diagonal-block .text-block {
    min-height: 314px; }
    .diagonal-block .text-block__cap {
      font-size: 18px;
      margin: 0 0 20px 0; }
      .diagonal-block .text-block__cap--m-t {
        margin: 20px 0; }
    .diagonal-block .text-block__text {
      font-size: 18px;
      line-height: 28px; }
  .hmi-list__item {
    align-items: center; }
  .hmi-list .text-block {
    margin: 0; }
    .hmi-list .text-block__text {
      font-size: 18px;
      line-height: 28px; }
  .s-achieves {
    padding-bottom: 30px; }
  .label--tape {
    max-width: 210px;
    height: 155px;
    padding: 10px; }
  .label__cap {
    font-size: 46px; }
  .label__text {
    font-size: 16px;
    line-height: 20px; }
  .s-team {
    padding-bottom: 0; }
  .teammate {
    width: 210px;
    height: 140px;
    margin: 0 16px 16px 0; }
    .teammate__text {
      padding: 0 0 5px 8px; }
    .teammate__name {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px; }
    .teammate__pos {
      line-height: 18px;
      font-size: 13px; }
  .s-customers .grid__cont {
    max-width: 940px; }
  .s-jobs .text-block__list {
    margin: 20px 0 0 0; }
  .c-grid__item {
    width: 425px; }
  .s-stories .c-grid__item {
    margin-bottom: 40px; }
  .foot-nav__links-col {
    max-width: 190px; }
  .foot-nav__contacts {
    width: 25%; }
  .foot-nav__copyright {
    width: auto;
    margin: 54px auto 0 auto; } }

@media screen and (max-width: 991px) {
  .head-nav {
    padding: 0 24px; }
  .content-holder, .foot-nav, .foot-form-block,
  .s-post .content-holder, .s-share .content-holder {
    padding: 0 57px;
    max-width: 768px; }
  .btn--to-top {
    right: 30px;
    bottom: 30px; }
  .btn--connect {
    max-width: 310px; }
  .s-entrance, .s-starting {
    padding-top: 88px; }
  header.head {
    min-height: 88px; }
  .logo {
    font-size: 24px; }
  .side-nav {
    max-width: none;
    padding: 27px 27px 0 27px; }
    .side-nav__group, .side-nav__group-2, .side-nav__row {
      width: 100%;
      max-width: 468px; }
    .side-nav__row {
      margin: 0 auto 30px auto; }
      .side-nav__row--close {
        max-width: none;
        margin-bottom: 25px; }
      .side-nav__row--lang {
        margin-bottom: 0; }
    .side-nav__group, .side-nav__group-2 {
      margin: 0 auto; }
    .side-nav__group-2 {
      justify-content: space-between; }
    .side-nav__cap {
      max-width: 205px; }
      .side-nav__cap--link {
        margin: 0;
        font-size: 16px; }
  .s-entrance .content-holder {
    flex-direction: column;
    justify-content: flex-start;
    min-height: auto; }
  .s-entrance .text-block {
    order: 2;
    max-width: none;
    margin-top: 70px; }
    .s-entrance .text-block .section-header {
      width: 100%;
      max-width: none;
      order: 1; }
      .s-entrance .text-block .section-header br {
        display: none; }
    .s-entrance .text-block__text {
      width: 100%;
      max-width: none;
      order: 3;
      margin-top: 70px; }
  .s-overview .text-block, .s-service .text-block, .s-service .c-grid {
    margin-top: 70px; }
  .s-overview .content-holder {
    flex-direction: column;
    align-items: center; }
  .s-overview .illustration {
    margin-top: 70px;
    padding-right: 40px; }
  .s-overview .text-block {
    max-width: none; }
  .s-service .content-holder--2 {
    padding-bottom: 70px; }
  .s-starting {
    align-items: flex-start;
    min-height: 614px;
    padding-bottom: 40px; }
    .s-starting .content-holder {
      flex-direction: column; }
    .s-starting .text-block {
      order: 2;
      width: 100%;
      max-width: none;
      margin: 0 0 0 0;
      padding: 0; }
      .s-starting .text-block__text {
        width: 100%;
        margin-top: 38px; }
    .s-starting .illustration {
      order: 1;
      width: 100%; }
      .s-starting .illustration__img {
        margin: 10px 0 0 0;
        max-width: 158px; }
      .s-starting .illustration--puzzle {
        width: 254px;
        height: 242px;
        align-self: flex-end;
        margin: 6px 0 -52px 0; }
      .s-starting .illustration__fragment--inno1 {
        width: 160px;
        top: auto;
        left: auto;
        right: 35px;
        bottom: 112px; }
      .s-starting .illustration__fragment--inno2 {
        width: 78px;
        bottom: 16px;
        left: auto;
        right: 119px; }
      .s-starting .illustration__fragment--inno3 {
        width: 22px;
        left: auto;
        right: 165px;
        top: auto;
        bottom: 36px; }
      .s-starting .illustration__fragment--inno4 {
        width: 58px;
        right: 98px;
        left: auto; }
      .s-starting .illustration__fragment--inno5 {
        width: 108px;
        right: 0; }
      .s-starting .illustration__fragment--inter1 {
        width: 75px;
        bottom: -4px;
        right: 128px; }
      .s-starting .illustration__fragment--inter2 {
        width: 92px;
        bottom: 100px;
        right: 39px; }
      .s-starting .illustration__fragment--inter3 {
        width: 42px;
        bottom: 141px;
        right: 0; }
      .s-starting .illustration__fragment--inter4 {
        bottom: 0;
        right: 93px;
        width: 52px; }
      .s-starting .illustration__fragment--inter5 {
        width: 95px;
        bottom: 38px;
        right: 2px; }
      .s-starting .illustration__fragment--inter6 {
        width: 100px;
        bottom: 0;
        right: 0; }
      .s-starting .illustration__fragment--comm1 {
        width: 67px;
        right: -3px;
        bottom: 78px; }
      .s-starting .illustration__fragment--comm2 {
        width: 84px;
        right: 91px;
        bottom: 138px; }
      .s-starting .illustration__fragment--comm3 {
        width: 70px;
        right: 74px;
        bottom: 179px; }
      .s-starting .illustration__fragment--comm4 {
        width: 104px;
        right: 139px;
        bottom: 52px; }
      .s-starting .illustration__fragment--comm5 {
        width: 106px;
        right: -4px;
        bottom: 5px; }
      .s-starting .illustration__fragment--comm6 {
        width: 44px;
        right: 80px;
        bottom: 80px; }
      .s-starting .illustration__fragment--intern1 {
        width: 80px;
        right: 48px;
        bottom: 161px; }
      .s-starting .illustration__fragment--intern2 {
        width: 155px;
        right: 100px;
        bottom: 83px; }
      .s-starting .illustration__fragment--intern3 {
        width: 78px;
        right: 60px;
        bottom: 80px; }
      .s-starting .illustration__fragment--intern4 {
        width: 72px;
        right: 2px;
        bottom: 25px; }
      .s-starting .illustration__fragment--intern5 {
        width: 79px;
        right: 26px;
        bottom: 50px;
        transform: scale(1, -1); }
    .s-starting--service {
      min-height: auto; }
      .s-starting--service .content-holder {
        margin-top: 70px; }
      .s-starting--service .text-block__text {
        max-width: none; }
    .s-starting--post .page-header {
      line-height: 111%; }
  .block-header {
    padding: 52px 0 34px 0; }
  .s-info .text-block__list {
    max-width: none; }
  .diagonal-block .illustration {
    left: -57px;
    max-width: 620px; }
  .diagonal-block .text-block {
    max-width: 500px;
    padding: 45px 0 45px 48px;
    min-height: 300px; }
  .diagonal-block--to-right {
    margin-top: 35px; }
    .diagonal-block--to-right .illustration {
      left: auto;
      right: -57px; }
    .diagonal-block--to-right .text-block {
      padding: 45px 48px 45px 0; }
  .text-block__list {
    width: 100%;
    max-width: none; }
  .s-hmi .content-holder {
    margin-top: 0; }
  .hmi-list {
    margin: 37px 0 0 0; }
    .hmi-list__item {
      flex-direction: column;
      padding: 0;
      margin: 108px 0 0 0; }
      .hmi-list__item:first-child {
        margin: 0; }
      .hmi-list__item:last-child {
        padding-bottom: 80px; }
    .hmi-list__item-num {
      top: -2px;
      font-size: 24px; }
    .hmi-list .text-block {
      max-width: none;
      padding: 0; }
      .hmi-list .text-block__cap {
        font-size: 24px; }
      .hmi-list .text-block__text {
        margin: 25px 0 0 0; }
    .hmi-list .illustration {
      max-width: none;
      margin: 35px 0 0 0; }
      .hmi-list .illustration__img {
        width: 100%;
        max-height: 304px; }
  .s-testing {
    padding-bottom: 70px; }
  .s-about {
    align-items: flex-end; }
    .s-about .about-top-pic {
      display: none; }
    .s-about .illustration__img {
      max-width: 320px; }
    .s-about--contacts .illustration {
      padding: 0;
      width: 100%;
      max-width: none; }
      .s-about--contacts .illustration__img {
        max-width: none; }
  .s-achieves .illustration {
    max-width: none; }
  .s-achieves .labels {
    flex-wrap: wrap;
    justify-content: space-between; }
  .s-achieves .label {
    max-width: calc(50% - 8px);
    margin-bottom: 16px;
    height: 200px; }
    .s-achieves .label:last-child {
      margin: 0 0 16px 0; }
    .s-achieves .label__cap {
      font-size: 64px;
      margin-bottom: 15px; }
    .s-achieves .label__text {
      font-size: 20px;
      line-height: 20px;
      max-width: 190px; }
  .s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    max-width: 520px;
    left: calc(50% - 260px); }
  .teammate {
    width: 279px;
    height: 186px;
    margin: 0 16px 16px 0; }
  .s-customers .grid {
    justify-content: center;
    margin: 0;
    width: 100%; }
    .s-customers .grid__cont {
      max-width: 654px;
      padding: 20px 0; }
  .s-customers .customer {
    margin: 0 0 40px 0;
    width: 33%; }
  .s-partners .partners-grid {
    margin-bottom: 30px; }
  .s-partners .grid {
    width: 100%;
    margin: 0; }
  .s-partners .partner {
    margin: 20px 0; }
  .label--social {
    height: 120px;
    max-width: 210px; }
    .label--social .label__img {
      max-height: 57px;
      max-width: 170px;
      mix-blend-mode: multiply; }
  .c-grid__item {
    width: calc(50% - 20px); }
  .s-service .c-grid__img {
    width: 90px; }
  .s-service .c-grid__item {
    padding: 20px 10px;
    min-height: 390px; }
  .s-service .c-grid__cap {
    font-size: 26px;
    margin: 15px 0; }
  .s-service .c-grid__text {
    font-size: 14px;
    line-height: 22px; }
  .s-service .c-grid__item .btn--link {
    margin: 10px auto;
    width: 180px;
    height: 46px;
    font-size: 14px; }
  .s-stories .c-grid__item {
    margin-bottom: 40px; }
  .s-stories .c-grid__img-container {
    height: 175px; }
  .s-stories .c-grid__cap {
    margin: 20px 0 0 0;
    padding: 0 15px 0 15px;
    font-size: 18px; }
  .s-stories .c-grid__text {
    padding: 0 20px 0 20px;
    font-size: 14px; }
  .f-contacts__input {
    max-width: 310px; }
  .f-contacts__ta {
    height: 240px; }
  .s-connect {
    padding-bottom: 140px; }
  .footer-form__input {
    width: calc(100% - 125px); }
  .footer-form__submit {
    width: 125px; }
  .foot-nav__links {
    max-width: 450px; }
  .foot-nav__links-row {
    justify-content: space-between; }
  .foot-nav__link {
    max-width: 143px; }
    .foot-nav__link:last-child {
      margin: 0; }
  .foot-nav__contacts {
    width: auto;
    max-width: none;
    padding: 8px 4px; }
  .foot-nav__copyright {
    margin: 83px 0 0 0; }
  .foot-nav__logo {
    order: 4;
    margin: 40px 0 0 0; } }

@media screen and (max-width: 767px) {
  body.home-page-body header.head.fixed, header.head.fixed, header.headп {
    min-height: 56px; }
  .content-holder, .foot-form-block,
  .s-post .content-holder, .s-share .content-holder {
    padding: 0 30px;
    max-width: 544px; }
  .s-starting .illustration--puzzle {
    margin: 0 0 0 0; }
  .s-starting--post {
    min-height: auto; }
    .s-starting--post .content-holder {
      margin-top: 50px; }
    .s-starting--post .text-block {
      margin-top: 0; }
    .s-starting--post .page-header {
      font-size: 52px;
      line-height: 111%; }
  .btn--vacancy {
    max-width: 290px;
    height: 60px;
    font-size: 16px;
    margin: 50px auto 0 auto; }
  .btn--share {
    width: 36px;
    height: 36px;
    margin: 0 20px 0 0;
    font-size: 18px; }
  .btn--connect {
    margin-bottom: 15px;
    max-width: 235px;
    height: 48px; }
  .s-entrance .text-block, .s-entrance .text-block__text {
    margin-top: 50px; }
  .diagonal-block .illustration {
    max-width: 460px; }
  .s-info {
    padding: 84px 0 0 0; }
  .s-internationalization .page-header {
    font-size: 55px; }
  .s-research, .s-hmi, .s-prototyping, .s-ergonomics, .s-testing {
    padding-top: 30px; }
  .s-achieves .label {
    max-width: 234px;
    margin: 0 0 16px 0;
    height: 180px; }
    .s-achieves .label__cap {
      font-size: 46px; }
    .s-achieves .label__text {
      font-size: 16px; }
    .s-achieves .label:last-child {
      margin: 0 0 16px 0; }
  .teammate {
    width: 210px;
    height: 140px; }
  .s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    max-width: 400px;
    left: calc(50% - 200px); }
  .s-customers .grid__cont {
    max-width: 484px; }
  .s-customers .customer {
    padding: 0 20px;
    justify-content: center; }
    .s-customers .customer:first-child, .s-customers .customer:nth-child(3n + 4) {
      padding: 0 20px 0 0; }
    .s-customers .customer:nth-child(3n), .s-customers .customer:last-child {
      padding: 0 0 0 20px; }
  .s-partners .partner {
    max-width: 33%;
    padding: 0 20px; }
    .s-partners .partner:first-child, .s-partners .partner:nth-child(3n + 4) {
      padding: 0 20px 0 0; }
    .s-partners .partner:nth-child(3n) {
      padding: 0 0 0 20px; }
  .c-grid__item {
    width: 100%;
    max-width: none; }
  .s-service .c-grid__item {
    padding: 32px;
    min-height: 442px; }
  .s-service .c-grid__img {
    width: 110px; }
  .s-service .c-grid__cap {
    margin: 30px 0;
    font-size: 36px;
    line-height: 48px; }
  .s-service .c-grid__text {
    font-size: 16px;
    line-height: 24px; }
  .s-service .c-grid__item .btn--link {
    width: 224px;
    height: 56px; }
  .s-stories .c-grid__cap,
  .s-stories .c-grid__text {
    padding: 0 50px 0 30px; }
  .s-stories .c-grid__item {
    margin-bottom: 40px; }
  .s-stories .c-grid__img-container {
    height: 255px; }
  .s-stories .c-grid__cap {
    font-size: 24px;
    line-height: 32px;
    margin: 50px 0 0 0; }
  .s-stories .c-grid__text {
    font-size: 16px;
    line-height: 24px;
    margin: 15px 0 0 0; }
  .post-sup-cap {
    font-size: 16px;
    line-height: 178%;
    padding-left: 30px; }
    .post-sup-cap:after {
      width: 22px; }
  .s-share {
    padding-top: 0;
    margin-bottom: 50px; }
  .s-contact-form {
    padding-bottom: 40px; }
  .f-contacts__input {
    max-width: 235px;
    height: 48px;
    margin-bottom: 15px; }
  .f-contacts__ta {
    height: 200px; }
  .s-connect {
    padding-bottom: 140px; }
  .footer-form {
    flex-direction: column; }
    .footer-form__label {
      margin-bottom: 40px; }
  .foot-nav__contacts, .foot-nav__links, .foot-nav__copyright {
    max-width: 544px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px; }
  .foot-divider {
    margin: 80px auto 40px auto; }
    .foot-divider--sm {
      display: block;
      margin: 0px auto; }
  .foot-nav {
    max-width: none;
    width: 100%;
    padding: 0; }
    .foot-nav__links-col {
      margin-bottom: 40px; }
      .foot-nav__links-col:last-child {
        width: auto; }
    .foot-nav__contacts {
      padding: 40px 30px; }
    .foot-nav__logo {
      display: none; }
    .foot-nav__copyright {
      display: flex;
      flex-wrap: wrap; }
      .foot-nav__copyright__item:first-child {
        width: auto;
        margin-bottom: 40px; } }

@media screen and (max-width: 543px) {
  .content-holder, .head-nav, .foot-form-block {
    padding: 0 15px; }
  .btn--to-top {
    right: 20px;
    bottom: 20px; }
  .btn--vacancy {
    margin: 50px auto; }
  .btn--connect {
    max-width: none; }
  .logo {
    margin-left: 0; }
    .logo__link {
      max-width: 138px; }
  .side-nav {
    padding: 15px;
    top: -100%;
    right: 0;
    bottom: auto; }
    .side-nav.nav-toggled {
      top: 0; }
    .side-nav__group, .side-nav__group-2, .side-nav__row {
      max-width: 320px; }
    .side-nav__group .side-nav__row {
      flex-direction: column; }
      .side-nav__group .side-nav__row--close {
        flex-direction: row; }
    .side-nav__group {
      min-height: auto;
      padding-bottom: 35px; }
    .side-nav__group-2 {
      flex-wrap: wrap;
      padding: 25px 0; }
    .side-nav__row {
      margin-bottom: 36px; }
      .side-nav__row--lang {
        justify-content: space-between;
        margin-bottom: 0; }
    .side-nav__cap--btn {
      position: relative;
      max-width: none;
      width: 100%;
      transition: color .2s linear 0s;
      cursor: pointer; }
      .side-nav__cap--btn:after {
        display: block;
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 3px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #000000 transparent transparent transparent;
        transition: all .3s linear 0s; }
      .side-nav__cap--btn:hover {
        color: #000000; }
    .side-nav__cap--btn.clicked {
      transition: color .2s linear 0s;
      color: #0166FF; }
      .side-nav__cap--btn.clicked:after {
        transform: rotate(180deg);
        border-color: #0166FF transparent transparent transparent; }
    .side-nav__links {
      display: none; }
      .side-nav__links--lang {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px; }
    .side-nav__link {
      display: block; }
    .side-nav__group .side-nav__link {
      margin: 8px 0 0 0; }
      .side-nav__group .side-nav__link:first-child {
        margin: 15px 0 0 0; }
  .page-header, .s-internationalization .page-header {
    font-size: 32px; }
  .section-header, .service-page-body .section-header,
  .s-info .section-header, .s-engagement .section-header, .s-jobs .section-header {
    font-size: 28px;
    line-height: 42px; }
  .sup-header, .block-header .sup-header {
    font-size: 12px;
    line-height: 1;
    margin: 0; }
  .text-block {
    font-size: 14px; }
    .text-block__list-cap {
      font-size: 16px;
      line-height: 28px; }
    .text-block__list-item {
      font-size: 14px;
      line-height: 22px; }
  .s-entrance, .s-starting {
    padding-top: 56px; }
  .block-header {
    padding: 0; }
  .s-entrance {
    min-height: auto; }
    .s-entrance .text-block .section-header {
      font-size: 28px;
      line-height: 117%; }
    .s-entrance .text-block__text {
      font-size: 16px;
      line-height: 22px; }
    .s-entrance .thumbs__row {
      flex-direction: column;
      margin: 0; }
    .s-entrance .thumbs__thumb {
      margin: 0 0 32px 0; }
    .s-entrance .thumbs__row .thumbs__thumb--mid, .s-entrance .thumbs__row .thumbs__thumb--side {
      width: 100%; }
  .s-overview .illustration {
    margin-top: 50px;
    width: 100%;
    padding-right: 9.5%; }
  .s-service .content-holder--2 {
    padding-bottom: 0; }
  .s-service .text-block__text {
    font-size: 16px;
    line-height: 28px; }
  .s-service .c-grid__item {
    padding: 0;
    margin-bottom: 40px;
    min-height: auto; }
    .s-service .c-grid__item .btn--link {
      width: 200px;
      height: 50px;
      margin: 30px auto; }
  .s-service .c-grid__cap {
    font-size: 22px;
    line-height: 24px;
    margin: 30px auto; }
  .s-starting .illustration {
    margin: 20px 0 0 0; }
    .s-starting .illustration__img {
      margin: 0;
      max-width: 80px; }
    .s-starting .illustration--puzzle {
      width: 114px;
      height: 142px;
      margin: 3px 0 -40px 0; }
    .s-starting .illustration__fragment--inno1 {
      width: 80px;
      right: 19px;
      bottom: 58px; }
    .s-starting .illustration__fragment--inno2 {
      width: 38px;
      bottom: 10px;
      right: 61px; }
    .s-starting .illustration__fragment--inno3 {
      width: 10px;
      right: 85px;
      bottom: 22px; }
    .s-starting .illustration__fragment--inno4 {
      width: 28px;
      right: 50px; }
    .s-starting .illustration__fragment--inno5 {
      width: 54px; }
    .s-starting .illustration__fragment--inter1 {
      width: 38px;
      bottom: -3px;
      right: 63px; }
    .s-starting .illustration__fragment--inter2 {
      width: 46px;
      bottom: 50px;
      right: 19px; }
    .s-starting .illustration__fragment--inter3 {
      width: 21px;
      bottom: 71px;
      right: 0; }
    .s-starting .illustration__fragment--inter4 {
      width: 27px;
      right: 45px; }
    .s-starting .illustration__fragment--inter5 {
      width: 48px;
      bottom: 19px;
      right: 1px; }
    .s-starting .illustration__fragment--inter6 {
      width: 50px;
      bottom: 0;
      right: 0; }
    .s-starting .illustration__fragment--comm1 {
      width: 34px;
      right: 74px;
      bottom: 95px; }
    .s-starting .illustration__fragment--comm2 {
      width: 42px;
      right: 30px;
      bottom: 80px; }
    .s-starting .illustration__fragment--comm3 {
      width: 35px;
      right: 18px;
      bottom: 67px; }
    .s-starting .illustration__fragment--comm4 {
      width: 52px;
      right: 61px;
      bottom: 34px; }
    .s-starting .illustration__fragment--comm5 {
      width: 53px;
      right: 0px;
      bottom: 30px; }
    .s-starting .illustration__fragment--comm6 {
      width: 22px;
      right: 0;
      bottom: 12px; }
    .s-starting .illustration__fragment--intern1 {
      width: 39px;
      right: 0px;
      bottom: 101px; }
    .s-starting .illustration__fragment--intern2 {
      width: 78px;
      right: 22px;
      bottom: 62px; }
    .s-starting .illustration__fragment--intern3 {
      width: 39px;
      right: 28px;
      bottom: 39px; }
    .s-starting .illustration__fragment--intern4 {
      width: 35px;
      right: 0px;
      bottom: 66px; }
    .s-starting .illustration__fragment--intern5 {
      width: 39px;
      right: 11px;
      bottom: 50px;
      transform: scale(1, 1); }
  .s-starting .text-block {
    margin: 0 0 0 0; }
    .s-starting .text-block__text {
      font-size: 16px;
      margin-top: 9px;
      line-height: 22px; }
  .s-starting--post .page-header {
    font-size: 32px; }
  .s-starting--post .text-block__text {
    font-size: 17px;
    line-height: 133%; }
  .s-diagonal.s-jobs .diagonal-block .illustration, .s-diagonal.s-engagement .diagonal-block .illustration {
    order: 1;
    margin: 30px -15px 15px -15px; }
  .s-diagonal.s-jobs .diagonal-block .text-block, .s-diagonal.s-engagement .diagonal-block .text-block {
    order: 2; }
  .diagonal-block {
    padding: 0;
    flex-direction: column; }
    .diagonal-block .illustration {
      position: initial;
      order: 2;
      width: calc(100% + 30px);
      max-width: none;
      margin: 8px -15px 0 -15px; }
      .diagonal-block .illustration img {
        width: 100%; }
    .diagonal-block .text-block {
      order: 1;
      box-shadow: none;
      padding: 0;
      min-height: auto;
      margin-top: 20px; }
      .diagonal-block .text-block__cap {
        font-size: 14px; }
      .diagonal-block .text-block__text {
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 20px 0; }
      .diagonal-block .text-block__list-cap {
        font-size: 14px;
        line-height: 28px; }
      .diagonal-block .text-block__list-item {
        font-size: 14px; }
    .diagonal-block--to-right {
      margin-top: 0; }
  .s-info {
    padding-top: 80px; }
    .s-info .section-header {
      margin-bottom: 30px; }
    .s-info .text-block__cap {
      font-size: 14px; }
    .s-info .text-block__text {
      font-size: 14px;
      line-height: 22px; }
    .s-info .text-block__list {
      margin: 25px 0; }
    .s-info .text-block__list-item {
      font-size: 14px;
      line-height: 22px; }
  .labels--social {
    flex-direction: column;
    align-items: center;
    margin: 50px 0; }
  .label--social {
    max-width: 290px;
    margin-bottom: 20px; }
  .s-interaction {
    min-height: auto; }
    .s-interaction .content-holder {
      justify-content: flex-start;
      min-height: auto; }
  .s-research {
    background: #ffffff; }
  .hmi-list {
    margin: 20px 0 0 0; }
    .hmi-list__item {
      margin: 70px 0 0 0; }
      .hmi-list__item:last-child {
        padding-bottom: 60px; }
    .hmi-list .text-block .hmi-list__item-num {
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      font-size: 20px; }
    .hmi-list .text-block__cap {
      font-size: 20px;
      line-height: 28px;
      padding: 2px 0 0 48px; }
    .hmi-list .text-block__text {
      font-size: 14px;
      line-height: 22px;
      margin-top: 20px; }
    .hmi-list .illustration {
      margin-top: 25px; }
  .s-testing {
    padding-bottom: 95px; }
  .s-jobs {
    padding-bottom: 70px; }
  .s-research, .s-hmi, .s-prototyping, .s-ergonomics, .s-testing {
    padding-top: 80px; }
  .s-about .page-header {
    margin: 20px 0; }
  .s-about .illustration {
    padding-left: 0; }
    .s-about .illustration__img {
      max-width: none; }
  .s-about--contacts .text-block {
    max-width: 290px; }
  .s-achieves {
    padding-bottom: 0; }
    .s-achieves .labels {
      margin-top: 50px;
      justify-content: center; }
    .s-achieves .label {
      width: 290px;
      max-width: none;
      height: 194px; }
      .s-achieves .label:last-child {
        margin-bottom: 0; }
  .s-team .grid {
    max-height: 445px; }
    .s-team .grid__cont {
      max-width: 290px;
      margin: 0 auto; }
  .s-team .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    max-width: calc(100% - 5px);
    left: 0; }
  .teammate {
    width: 250px;
    height: 167px; }
    .teammate__name {
      font-size: 18px;
      line-height: 22px; }
    .teammate__pos {
      font-size: 14px;
      line-height: 28px; }
  .s-customers .grid__cont {
    padding-bottom: 0; }
  .s-partners .partner {
    max-width: 49%; }
    .s-partners .partner:nth-child(even) {
      padding: 0 0 0 20px; }
    .s-partners .partner:nth-child(odd) {
      padding: 0 20px 0 0; }
  .s-stories .content-holder {
    padding-bottom: 77px; }
  .s-stories .section-header {
    margin: 39px auto; }
  .s-stories .c-grid__item {
    padding: 0 0 25px 0;
    margin-bottom: 48px; }
  .s-stories .c-grid__img-container {
    height: auto; }
  .s-stories .c-grid__cap, .s-stories .c-grid__text {
    padding: 0 16px 0 16px; }
  .s-stories .c-grid__cap {
    font-size: 20px;
    line-height: 28px;
    margin: 28px 0 0 0; }
  .s-stories .c-grid__text {
    font-size: 14px;
    line-height: 24px;
    margin: 20px 0 0 0; }
  .s-stories .btn--link {
    width: 200px;
    height: 50px;
    font-size: 12px;
    line-height: 24px; }
  .post-sup-cap {
    margin-bottom: 10px; }
  .s-post-illustration {
    padding: 25px 0; }
  .s-post {
    padding: 25px 0; }
  .post-p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 28px 0; }
  .post-h3 {
    font-size: 24px;
    line-height: 28px; }
  .post-h4 {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 28px 0; }
  .post-pic--full {
    margin: 22px 0 49px 0; }
  .s-share .content-holder {
    flex-direction: column;
    align-items: center; }
  .share-links {
    margin: 25px 0 0 0; }
  .s-contact-form {
    padding-top: 0; }
    .s-contact-form .content-holder {
      max-width: 320px; }
  .f-contacts__input {
    max-width: none; }
  .f-contacts .btn--submit {
    width: 100%; }
  .s-connect {
    padding: 10px 0 100px 0; }
    .s-connect .content-holder {
      max-width: 320px; }
    .s-connect .side-left {
      margin-top: 50px; }
  .s-privacy, .s-imprint {
    padding: 25px 0 50px 0; }
  footer.page-footer {
    padding: 47px 0 100px 0; }
  .footer-form__label {
    margin-bottom: 28px; }
  .footer-form__input {
    width: calc(100% - 92px);
    font-size: 14px;
    line-height: 16px;
    padding: 8px; }
  .footer-form__submit {
    width: 92px;
    font-size: 12px;
    line-height: 14px; }
  .foot-divider {
    margin: 46px auto 30px auto; }
    .foot-divider--sm {
      margin: 0px auto 0px auto; }
  .foot-nav__contacts, .foot-nav__links, .foot-nav__copyright {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px; }
  .foot-nav__links {
    flex-direction: column; }
  .foot-nav__link {
    font-size: 18px;
    line-height: 32px; }
  .foot-nav__links-col {
    margin-bottom: 27px; }
  .foot-nav__contacts {
    padding: 30px 15px; }
  .foot-nav__contacts-item {
    font-size: 14px;
    line-height: 20px; }
  .foot-nav__copyright__item {
    font-size: 14px;
    line-height: 16px; }
    .foot-nav__copyright__item:first-child {
      margin-bottom: 30px;
      width: 100%; } }
