footer.page-footer{
  background: #F5F5F7;
  padding: 88px 0 100px 0;
}
.footer-form{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__label{
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    font-weight: bold;
  }
  &__input-wrapper{
    display: flex;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.0379152);
    width: 100%;
    max-width: 530px;
  }
  &__input, &__submit{
    height: 48px;
  }
  &__input{
    background: #FFFFFF;
    width: calc(100% - 155px);
    border-radius: 4px 0 0 4px ;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px;
  }
  &__submit{
    width: 155px;
    border-radius: 0 4px 4px 0;
  }
}

.foot-divider{
  height: 1px;
  border: none;
  background: #D1D1D1;
  display: block;
  margin: 84px auto 75px auto;
  width: 100%;
  &--sm{
    display: none;
  }
}

.foot-nav{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  &__links{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 50%;
    width: 100%;
  }
  &__links-col{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 49%;
  }
  &__link{
    color: #0166FF;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    width: 100%;
    max-width: 200px;
  }

  &__contacts{
    width: 100%;
    max-width: calc(50% - 70px);
    padding: 5px 15px;
  }
  &__contacts-item{
    font-size: 16px;
    line-height: 24px;

    color: #6F6F6F;
    &--cap{
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    &--link{
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__logo{
    width: 70px;
    margin: 20px 0 0 0;
  }

  &__copyright{
    width: 100%;
    margin: 54px 0 0 0;
    &__item{
      color: #6F6F6F;
      font-size: 16px;
      margin: 0 45px 0 0;
    }
  }
}